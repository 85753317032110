@import '../../../assets/styles/globals.scss';

.student-card-wrapper{
  display: flex;
  border: 1px solid $light-gray;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  height: 70px;

  .student-data {
    height: 100%;
    width: 100%;

    p {
      margin-bottom: 0;
      font-weight: 700;
    }

    .added-from-wrapper {
      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .programPackage {
        font-size: 12px;
      }
    }
  }

  .student-title{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .student-date{
    color: $light-navy;
  }

  .student-level {
    font-size: 25px;
  }

  .x-button-wrapper {
    cursor: pointer;
  }
}
