@import "../../assets/styles/globals";

:root {
  --location-type-modal-x-button-size: #{$heading-1-size};
  --location-type-modal-spacing: 10px;
  --location-type-modal-title-size: #{$heading-2-size};
  --location-type-modal-paragraph-size: 16px;
  --location-type-modal-pin-height: 72px;
}

// Added this class in case modifying the ReactModalPortal would mess up modals
// used in other parts of the app
.ReactModalPortal__LocationTypeModal {
  z-index: 100010; // B/c z-index of top bar and Google Maps components are HUGE
  position: relative;
}

#LocationTypeModal {
  background-color: white;
  max-width: 500px;

  border-radius: $card-corner-radius;
  box-shadow: $modal-drop-shadow;
  padding: $secondary-padding;

  .location-type-modal-top-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--location-type-modal-spacing);

    .x-modal-close-btn {
      i {
        font-size: var(--location-type-modal-x-button-size);
      }
    }
  }

  h3 {
    font-size: var(--location-type-modal-title-size);
    font-weight: $bold-font-weight;
    text-align: center;
    margin-bottom: $secondary-padding;
  }

  .location-type-modal-section {
    p {
      font-size: var(--location-type-modal-paragraph-size);
      margin-bottom: 0px;
    }
  }

  #location-type-modal-section-1 {
    margin-bottom: $secondary-padding;
  }

  .location-type-modal-section-header {
    display: flex;
    flex-flow: wrap;
    margin-bottom: var(--location-type-modal-spacing);
  }

  .location-type-tag {
    padding: 6px 8px;
    color: white;
    font-size: var(--location-type-modal-paragraph-size);
    font-weight: $bold-font-weight;
    border-radius: 999px;
    margin-right: var(--location-type-modal-spacing);
    align-self: center;
  }

  .location-type-tag-league {
    background-color: $primary-blue;
  }

  .location-type-tag-coaching {
    background-color: $primary-teal;
  }

  .location-type-modal-section-pins {
    display: flex;
    flex-flow: nowrap;

    img {
      max-height: var(--location-type-modal-pin-height);
      margin-right: var(--location-type-modal-spacing);
    }
  }
}

@media screen and (max-width: 425px) {
  :root {
    --location-type-modal-title-size: #{$heading-3-size};
    --location-type-modal-paragraph-size: 14px;
    --location-type-modal-pin-height: 48px;
  }
}

@media screen and (max-width: 320px) {
  :root {
    --location-type-modal-x-button-size: #{$heading-2-size};
    --location-type-modal-title-size: #{$heading-4-size};
    --location-type-modal-paragraph-size: 12px;
    --location-type-modal-pin-height: 36px;
  }
}
