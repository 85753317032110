@import "../../../../assets/styles/globals";

#GolfersTabContainer {
  padding: 0 18px;
  margin: 0 -15px;

  .golfers-header{

    .golfers-count {
      width: 100%;
      font-weight: 700;
      font-size: 24px;

      p {
        margin-bottom: 0;
        word-break: break-word;
      }
    }

    .view-roster {
      white-space: nowrap;
      font-size: 16px;
      padding: 0 15px;
      cursor: pointer;

      i {
        color: $primary-orange;
        font-size: 20px;
      }

      .view-roster-label {
        padding-left: 10px;
        color: $primary-orange;
        font-weight: 700;
      }
    }

    .add-students-button {
        background-color: #36434e;
        white-space: nowrap;
        border-radius: 25px;
        border: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 150px;
        padding: 8px 0;
        color: $off-white;
        font-size: 16px;
    }

    #roundedPlusIcon {
      flex-shrink: 0;
      margin-right: 10px;
    }

    @media (max-width: 768px) {
      justify-content: space-between !important;
      flex-wrap: wrap;

      .golfers-count {
        width: 50%;
      }

      .view-roster {
        width: 50%;
        padding-right: 0;
        justify-content: flex-end;

        .view-roster-label {
          text-align: center;
          white-space: normal;
        }
      }
    }
  }
}
