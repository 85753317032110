@import "../../../../assets/styles/globals.scss";

#CreateGolfersStep {
  .title {
    font-size: 27px;
    font-weight: 550;
    color: $primary-color;
    text-align: center;
  }

  .description {
    font-size: 16px;
    color: $primary-color;
    text-align: center;
  }

  .user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 100%;
    margin: auto;
  }

  .add-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: -10px;
    padding-bottom: 45px;
    border-bottom: 1px solid $light-font-color;

    .add-golfer-btn-wrapper {
      display: flex;
      width: 100%;
      height: 50px;
      padding: 0 15px;

      .add-golfer-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-size: 15px;

        .add-icon {
          font-size: 20px;
          padding-right: 5px;
        }
      }

      @media screen and (min-width: 768px) {
        flex: 0 0 50%;
      }
    }
  }

  .dashboard-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 50px;

    .course-name {
      font-size: 16px;
      margin-bottom: 5px;
      max-width: 100%;
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .finished-text {
      font-size: 23px;
      font-weight: 500;
      margin-bottom: 15px;
      color: $primary-color;
    }

    button {
      margin: 10px !important;
    }
  }
}
