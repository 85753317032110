@import "../../assets/styles/globals";

#NotificationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid lighten($light-font-color, 15%);
  color: lighten($primary-color, 15%);
  padding-bottom: 10px;
  padding-top: 10px;

  &:hover {
    cursor: pointer;
    color: $light-font-color;
  }

  .notification-icon {
    font-size: 16px;
    margin-right: 10px;
  }

  .notification-message {
    margin: 0px;
    font-size: 11px;
  }
}
