@import "../../../assets/styles/globals";

// We might want to use these styles for the SubmitButton too
.button-link {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  @include border-radius(500px);
  box-shadow: none;
  border: none;
  @include transition(.2s);

  &:hover {
    // We want to keep it like this until making changes to the colour palette
    background-color: $secondary-orange;
    color: white;
    transform: scale(95%);
  }

  &:focus, &:active {
    color: $white-font-color;
  }
}

.orange {
  background-color: $primary-orange;
}

.dark-navy {
  background-color: $primary-navy !important;
}
