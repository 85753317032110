@import "../../../../assets/styles/globals";

#GolfersTable {
  margin-top: 14px !important;
  background-color: white;
  margin: 0 -18px;

  .table-wrapper {
    width: 100%;
    height: calc(100vh - 196px);
  }

  table {
    margin-bottom: 75px;
    border-bottom: 2px solid $placeholder-gray !important;
  }

  .payment-status-column-header {
    min-width: 145px;
    white-space: nowrap;
  }

  .tuition-column-header, .points-column-header, .date-joined-column-header {
    white-space: nowrap;
    text-align: center;
  }

  .actions-column-header {
    min-width: 186px;
    width: 186px;
    text-align: center;
  }

  .table-header {
    text-transform: none;
    border-top: 0 !important;
  }

  .cell {
    padding: 10px 0px;
  }

  .table-body {
    .tr {
      height: 67px;
      border-top: 2px solid $placeholder-gray;

      .td {
        padding: 10px 0px;
      }

      .golfer-avatar-wrapper {
        overflow: hidden;
        flex-shrink: 0;
        border: 2px solid #1D2933;
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }

      .golfer-info {
        width: 100%;
        min-width: 125px;
        padding-left: 10px;

        .golfer-name {
          color: $dark-navy;
          font-weight: 700;
          margin-bottom: 0;
        }

        .golfer-username-or-email {
          color: $light-navy;
          font-size: 14px;
          margin-bottom: 0;
        }
      }

      .family-column {
        min-width: 100px;
        overflow: hidden;
      }

      .package-column {
        min-width: 200px;
      }

      .tuition-column, .points-column, .date-joined-column {
        text-align: center;
      }

      .payment-status-column {
        white-space: nowrap;
      }
    }
  }
}
