@import "../../../../assets/styles/globals";

#EducationLesson {
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $light-gray !important;

    &:hover {
      background-color: $light-gray;
    }
  }
}
