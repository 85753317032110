@import "../../../../assets/styles/globals";

#SimpleEducationLesson {
  width: 100%;

  p {
    margin: 0px;
  }

  .lesson-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $light-gray !important;
    margin: 0px;

    &:hover {
      background-color: $light-gray;
    }
  }

  .lesson-complete-button {
    display: flex;
    justify-content: flex-end;
    color: $primary-color;

    &:hover {
      color: lighten($primary-color, 20%);
    }
  }

  .selected-lesson {
    background-color: $light-gray !important;
  }
}
