@import '../../globals';

$transition-duration: .1s;
$transition-timing: ease-in-out;

#navBarComponent{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 62px;
  background: $primary-color;
  color: $white-font-color;
  z-index: 999;

  &.menu-shown {
    #activitySocialMenu {
      transition: $transition-duration $transition-timing;
      opacity: 1;
      z-index: 100;
      visibility: visible;
    }
    #thirdIcon img {
      transform: rotate(45deg);
    }
  }

  #activitySocialMenu {
    transition: $transition-duration $transition-timing;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  }

  .iconColumn{
    text-align: center;
    .icon{
      max-height: 50px;
      padding-top: 12px;
    }
  }
  #firstIcon{
    position: absolute;
    width: 20%;
    height: 100%;
    left: 0px;
  }
  #secondIcon{
    position: absolute;
    width: 20%;
    height: 100%;
    left: 20%;
  }
  #thirdIcon{
    position: absolute;
    width: 20%;
    height: 100%;
    left: 40%;
    z-index: 9999999;

    img{
      max-height: 70px;
      height: 60px;
      padding-top: 5px;
      transition: $transition-duration $transition-timing;
    }
  }
  #fourthIcon{
    position: absolute;
    width: 20%;
    height: 100%;
    left: 60%;
  }
  #fifthIcon{
    position: absolute;
    width: 20%;
    height: 100%;
    left: 80%;
  }
}
