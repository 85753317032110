@import '../../../assets/styles/globals.scss';

.coachDashboardOp36 {
  height: calc(100vh - $page-header-height - 40px);
  display: flex;
  flex-direction: row;
  
  .dashboardContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow-y: hidden;
  }

  .communityLeaderboard {
    width: 50%;
    height: max-content;
    margin-top: 20px;
    margin-left: 40px;
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;

    .leaderboardHeader {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 18px;
      font-weight: 700;
      margin-left: 20px;
    }
  
    .tabList {
      margin-bottom: 0px;
      margin-left: 20px;
      margin-right: 20px;
      height: 40px;
      background-color: white;
      border-bottom: 2px solid $light-gray !important;
  
      .tab {
        margin-top: 0px;
        text-transform: none;
        background-color: transparent;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        height: 40px;
        font-weight: bold;
        color: black;
      }
  
      .activeTab {
        border-bottom: 4px solid $secondary-color;
        height: 40px;
        color: $secondary-color;
        background: transparent !important;
      }
    }
  }

  .communityLeaderboardRanking {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;

    .resetFiltersButton {
      padding: 0.5rem;
      background: $red-delete;
      color: white;
      border-radius: 40px;
      border: none;
      font-size: 16px;
      margin-right: 30px;
    
      &:active {
        background: $red-delete;
      }
    }

    .noActivities {
      width: 80%;
      margin: auto;
      margin-top: 20px;

      .noActivitiesTitle {
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
      }

      .noActivitiesText {
        color: $dark-gray;
      }
    }
  }

  .communityLeaderboardRankingHeight {
    height: 680px;
  }

  .allTimeTotals {
    width: 30%;
    height: max-content;
    padding-left: 40px;
    padding-top: 20px;
    background-color: white;
    border-radius: 10px;
    margin-left: 40px;
    margin-top: 20px;

    .title {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .allTimeTotalsItem {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .infoWrapper {
      margin-left: 20px;

      .info {
        font-size: 26px;
        font-weight: 700;
      }

      .itemTitle {
        font-size: 16px;
      }
    }
  }

  .leaderboardStatsItem {
    margin-top: 20px;
    display: flex;
    align-items: baseline;
    margin-right: 25px;

    .info {
      margin-left: 10px;
    }

    .data {
      font-size: 26px;
      font-weight: 700;
    }

    .text {
      font-size: 16px;
    }
  }

  .exportButton {
    background-color: $off-white !important;
    color: $dark-navy;
    padding: 5px 15px;

    &:active, &:focus, &:hover {
      background-color: $off-white !important;
      color: $dark-navy;
    }

    &:disabled {
      color: $dark-navy;
    }

    .exportIcon {
      margin-left: 5px;
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .coachDashboardOp36 {
    .communityLeaderboard {
      .title { 
        font-size: 16px;
      }

      .tabList {
        margin-left: 10px;
        margin-right: 10px;

        .tab {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .communityLeaderboardRanking {
      height: 380px;
    }

    .communityLeaderboardRankingHeight {
      height: 450px;
    }

    .allTimeTotalsItem {
      margin-top: 20px;
      margin-bottom: 20px;
  
      .infoWrapper {

        .info {
          font-size: 20px;
          font-weight: 700;
        }
  
        .itemTitle {
          font-size: 14px;
        }
      }
    }

    .leaderboardStatsItem {  
      margin-right: 10px;

      .data {
        font-size: 20px;
        font-weight: 700;
      }
  
      .text {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .coachDashboardOp36 {
    .communityLeaderboard {
      .tabList {
        .tab {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 12px;
        }
      }

      .exportButton {
        font-size: 14px;
      }
    }

    .communityLeaderboardRanking {
      height: 320px;
    }

    .communityLeaderboardRankingHeight {
      height: 385px;
    }

    .communityLeaderboardRanking {
      .noActivities {  
        .noActivitiesTitle {
          font-size: 18px;
        }
        .noActivitiesText {
          font-size: 14px;
        }
      }
    }

    .allTimeTotalsItem {
      margin-top: 15px;
      margin-bottom: 10px;
  
      .infoWrapper {

        .info {
          font-size: 18px;
        }
  
        .itemTitle {
          font-size: 12px;
        }
      }
    }

    .leaderboardStatsItem {  
      margin-right: 5px;

      .data {
        font-size: 18px;
      }
  
      .text {
        font-size: 12px;
      }
    }
  }
}