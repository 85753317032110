@import "../../assets/styles/globals";

#EducationsCard {
  // flex: 0 0 280px;
  width: 280px;
  background: #fff;
  margin: 9px 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  padding-bottom: 30px;
  z-index: 1;
  p {
    margin: 0;
    line-height: 1;
  }
  .card-header-photo {
    height: 165px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .default-icon {
      height: 108px;
      flex: 0 0 108px;
      background-position: center;
      background-size: cover;
    }
    i {
      color: #fff;
      font-size: 72px;
    }
  }
  .lp-card-title-bar {
    padding: 10px;
    background: #36434e;
    .lp-card-title {
      color: #fff;
      font-size: 18px;
    }
  }
  .lp-card-notes-section {
    padding: 10px;
    color: #5f6f7c;
    .lp-card-notes {
      font-size: 14px;
    }
  }
  .lp-card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    .lp-card-session-box,
    .lp-card-tag-box {
      display: flex;
      align-items: center;
    }
    .session-count-icon {
      flex: 0 0 20px;
      height: 20px;
      margin-right: 6px;
      background-size: cover;
      background-position: center;
    }
    .session-count-text {
      color: $primary-orange;
      white-space: nowrap;
      span {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .fa-check-circle-o {
      font-size: 24px;
      margin-right: 3px;
      color: #33d9b2;
    }
    .lp-card-tag-count {
      font-size: 14px;
      // text-decoration: underline;
      border-bottom: 1px solid #5f6f7c;
      color: #5f6f7c;
    }
  }
  .grey-out {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(95, 111, 124, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    &:hover {
      cursor: pointer;
    }
    i {
      font-size: 26px;
      color: #fff;
      position: absolute;
      &:hover {
        color: $primary-orange;
      }
    }
    .fa-pencil-square-o {
      top: 18px;
      right: 18px;
      padding: 0 10px;
    }
  }

  // Better spacing for progress bar in card
  #EducationProgressBar {
    padding: 0px 10px;
  }
}
