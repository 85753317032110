@import "../../../../assets/styles/globals.scss";

#UserCard {
  margin-bottom: 30px;
  background-color: $light-navy;
  padding: 15px;
  height: 300px;

  .user-edit-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-type-pill {
      background-color: $primary-color;
      border-radius: 500vh;

      .user-type-text {
        margin: 0px;
        color: $white-font-color;
        padding: 3px 15px;
      }
    }

    .active {
      background-color: $primary-teal;
    }

    .user-edit-btn {
      color: $white-font-color;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(0.95);
        color: darken($white-font-color, 15%);
        text-decoration: none;
      }
    }
  }

  .user-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;

    .user-name {
      font-size: 22px;
      color: $white-font-color;
      font-weight: 550;
      margin-top: 10px;
    }

    .user-username {
      color: $white-font-color;
      font-size: 14px;
    }
  }
}
