#CommunityPlaceholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2b3843;
  img {
    // display: inline-block;
    height: 100px;
  }
  i {
    font-size: 180px;
  }
  p {
    font-size: 14px;
    text-align: center;
  }
  @media screen and (max-width: 830px) {
    display: none;
  }
}
