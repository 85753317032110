@import "../../../assets/styles/globals";

#CoachStats {
  padding: 10px;

  .lead-coach-button {
    display: inline-block;
    font-size: 0.9rem;
    color: $primary-orange;
    cursor: pointer;
  }

  input {
    font-size: 0.8rem;
    max-width: 100%;
  }
  form {
    overflow-x: auto;
    .input-label {
      font-size: 0.8rem;
    }
  }

  #form-save {
    margin-top: 10px;
    text-align: center;
    color: $secondary-color;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .coach-contact-container {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: 15px;
    margin-bottom: 15px;

    h4 {
      font-weight: 550;
      font-size: 18px;
      margin: 0px;
      margin-bottom: 10px;
      #contact-info-edit {
        color: $secondary-color;
        float: right;
        font-size: 1rem;
        &:hover {
          color: #c36508;
          cursor: pointer;
        }
      }
    }

    p {
      color: $light-font-color;
      margin: 0px;

      span {
        color: $primary-color;
      }
    }
  }

  .row {
    .col-6,
    .col-12 {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-weight: 550;
        font-size: 40px;
      }

      p {
        text-align: center;
        font-size: 12px;
        color: $light-font-color;
      }
    }
  }
}
