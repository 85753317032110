@import '../../../assets/styles/globals.scss';

.communityLeaderboardStudentRow {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  align-items: center;
  width: 80%;
  min-height: 95px;
  margin: auto;
  margin-top: 20px;
  padding-left: 5px;
  padding-right: 5px;

  .studentInfo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 700;
    font-size: 14px;

    .rank {
      width: 40px;
    }

    .profileImg {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
    }

    .name {
      margin: 0;
      width: 130px;
      padding-left: 10px;
    }
  }
}

.topRank {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.firstPlace {
  background-image: url("../../../assets/images/common/communityLeaderboard/GradientFirstPlace.svg");

  .medal {
    background-image: url("../../../assets/images/common/communityLeaderboard/Medal.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 95px;
    height: 95px;
    float: right;
  }
}

.secondPlace {
  background-image: url("../../../assets/images/common/communityLeaderboard/GradientSecondPlace.svg");
}

.thirdPlace {
  background-image: url("../../../assets/images/common/communityLeaderboard/GradientThirdPlace.svg");
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .communityLeaderboardStudentRow {
    min-height: 85px;

    .studentInfo {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .firstPlace {
    .medal {
      width: 85px;
      height: 85px;
    }
  }

  .profileImg {
    height: 45px;
    width: 45px;
  }
}

@media (max-width: 1200px) {
  .communityLeaderboardStudentRow {
    min-height: 75px;
  }

  .firstPlace {
    .medal {
      width: 75px;
      height: 75px;
    }
  }

  .profileImg {
    height: 35px;
    width: 35px;
  }
}
