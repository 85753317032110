@import "../../../../assets/styles/globals";

#RegistrationCompletedFooter {
  min-height: 70px;
  flex-grow: 1;
  max-height: 8%;
  position: sticky;
  bottom: 0;

  @media (min-width: 1200px) {
    max-height: 13%;
  }

  .content-wrapper {
    background: $off-white;
    z-index: 1;

    .app-details-wrapper {
      position: absolute;
      height: 160px;
      overflow-y: hidden;
      bottom: calc(100% - 20px);
      z-index: -1;

      .app-mock-img {
        height: 250px;
        position: relative;
      }

      .app-mock-background-img {
        position: relative;
        margin-right: -20px;
        height: 200px;
      }
    }

    .qr-code-wrapper {
      position: absolute;
      bottom: 5px;
      height: 100px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 5px solid $primary-orange;
      padding: 5px;
      border-radius: 10px;
      background-color: white;

      @media (min-width: 1200px) {
        height: 110px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  }
}
