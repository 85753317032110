@import "../../../assets/styles/globals";

.submit-button {
  position: relative;
  box-shadow: none !important;
  border: none;

  &:focus, &:active {
    outline: none;
    border: none;
  }
}

.orange-button {
  background-color: $primary-orange !important;

  &:focus, &:active {
    background-color: $primary-orange !important;
  }

  &:disabled:hover {
    background-color: $primary-orange !important;
  }
}

.navy-button {
  background-color: $primary-navy !important;

  &:focus, &:active {
    background-color: $primary-navy !important;
  }

  &:disabled:hover {
    background-color: $primary-navy !important;
  }
}
