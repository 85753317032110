@import "../../assets/styles/globals";

#ParentClassCard {
  button {
    width: 100px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
  }
  .class-card-notification {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9999;
    background-color: $secondary-color;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0px;
      font-weight: 550;
      color: $white-font-color;
    }
  }
  .card {
    height: 175px;
    overflow: hidden;

    .class-card-header {
      height: 85px;
      background-image: linear-gradient(
        to right,
        $light-navy,
        $primary-color
      ) !important;
    }

    .privacy-status {
      position: absolute;
      right: 10px;
      top: 5px;
      display: flex;
      align-items: center;
      flex-direction: row;

      p {
        margin: 0px;
        color: $light-gray;
        font-size: 12px;
      }

      i {
        color: $light-gray;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .class-card-info-wrapper {
      position: absolute;
      top: 57px;
      left: 15px;
      display: flex;
      align-items: center;

      .class-card-photo-wrapper {
        height: 60px;
        width: 60px;
        overflow: hidden;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        background-color: $light-gray;
        // -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.2);
        // -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.2);
        // box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.2);

        i {
          color: $light-font-color;
          font-size: 30px;
        }

        img {
          width: 100%;
        }
      }

      h6 {
        color: $white-font-color;
        font-size: 18px;
        font-weight: 550;
        margin-left: 10px;
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        margin: 0px;
        margin-left: 10px;
        font-size: 14px;
        color: $light-font-color;
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .class-card-enrollments-container {
      position: absolute;
      bottom: 5px;
      right: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;

      p {
        margin-bottom: 0px;
        margin-left: 10px;
        font-size: 12px;
        color: $light-font-color;
      }

      .enrollment-avatar-wrapper {
        text-align: center;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        height: 30px;
        width: 30px;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -8px;
        border: 2px solid white;

        img {
          width: 100%;
        }
      }
    }
  }
}
