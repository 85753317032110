@import '../../../assets/styles/globals.scss';

.calendarWrapper {
  margin-top: 30px;
  border-radius: 10px;
  padding-top: 20px;
  width: 470px;
  height: 465px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;

  .react-calendar {
    border: none;
    border-radius: 10px;
  }

  .react-calendar__navigation button {
    color: black;
    font-weight: 700;
    font-size: 20px;

    &:hover {
      background: none;
    }

    &:focus {
      background: none;
    }

    &:active {
      background: none;
    }
  }

  .react-calendar__navigation__label {
    cursor: not-allowed;
    pointer-events:none;
  }

  .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button{
    display: none;
  }

  .react-calendar__month-view__weekdays {
    text-transform: none;
    font-weight: 500;

    .react-calendar__month-view__weekdays__weekday {

      abbr[title] {
        text-decoration: none;
        font-size: 14px;
      }
    }
  }

  .react-calendar__month-view__days {
    .react-calendar__tile {
      color: $light-navy;
      font-size: 17px;
      padding-top: 0px;
      padding-bottom: 0px;

      &:hover {
        background: none;
      }

      abbr {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: $placeholder-gray;
    }

    .react-calendar__tile--now {
      background: none;
      color: white;
      font-weight: 600;

      abbr {
        background: $light-navy;
        border-radius: 60%;
      }
    }
    .react-calendar__tile--active {
      background: none;
      color: $primary-orange;
      font-weight: 600;
      
      abbr {
        background: $background-color;
        border-radius: 60%;
      }
    }
  }

  .nextArrow {
    fill: $placeholder-gray;
    height: 20px;
    width: 13px;
  }

  .prevArrow {
    transform: rotate(180deg);
    fill: $placeholder-gray;
    height: 20px;
    width: 13px;
  }

  .programDate {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    background-color: $dark-navy;
    margin-left: 12.5px;
    margin-top: 2px;
    margin-bottom: 8px;
  }

  .eventDate {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    background-color: $dark-teal;
    margin-left: 12.5px;
    margin-top: 2px;
    margin-bottom: 8px;
  }

  .eventAndProgram {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;


    .noMargin {
      margin-left: 1px;
    }
  }

  .noProgramDate {
    height: 8px;
    width: 8px;
    margin-top: 10px;
  }

  .calendarLegend {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: flex-start;
    margin-left: 60px;
    margin-top: 20px;

    span {
      font-size: 12px;
      margin-bottom: 8px;
      margin-left: 10px;
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .calendarWrapper {
    width: 400px;

    .calendarLegend {
      margin-left: 25px;
    }
  }
}
