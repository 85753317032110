@import "../../../../assets/styles/globals.scss";

#FamilyAdminStep {
  .title {
    font-size: 27px;
    font-weight: 550;
    color: $primary-color;
    text-align: center;
  }

  .description {
    color: $primary-color;
    text-align: center;
    margin-bottom: 0px;
  }

  .important-note {
    color: $secondary-color;
    font-size: 14px;
    text-align: center;
  }
  .already-user {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .signin-link:hover {
    text-decoration: underline;
  }
}


@media all and (min-width: 1200px) {
  .registration-form {
    max-width: 60% !important;
  }
}
