@import "~bootstrap/scss/bootstrap.scss";
@import "~font-awesome/css/font-awesome.css";
// @import "~fixed-data-table/dist/fixed-data-table.min.css";
@import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import "../../../node_modules/loaders.css/src/animations/ball-pulse.scss";

@import "globals";
@import "common/helper";

:root {
  /* Brand colors */
  // Navy
  --navy-light: #5f6f7c;
  --navy-normal: #36434e;
  --navy-dark: #1d2933;
  // Orange
  --orange-normal: #ff6633; // btn-primary BG color
  // Purple
  --purple-light: #9eaaf6;
  --purple-normal: #6677de;
  --purple-dark: #243497;
  // Blue
  --blue-light: #79d1f7;
  --blue-normal: #34ace0;
  --blue-dark: #0e7cac;
  // Teal
  --teal-light: #86eed5;
  --teal-normal: #33d9b2;
  --teal-dark: #12a482;
  // Other
  --white: #ffffff;
  --off-white: #f4f4f5;

  // Override toast colors
  --toastify-color-success: #12A482;
  --toastify-color-error: #851D2D;
  --toastify-color-info: #ff6633;;
}

html {
  user-select: text;
  -webkit-user-select: text;
  height: 100%;
}

body {
  color: $dark-navy; // Default color for text
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  height: 100%;

  @media print {
    height: auto;
  }

  #root {
    width: 100%;
    height: 100%;

    .content {
      height: 100%;
    }
  }

  a {
    color: $secondary-color;
    cursor: pointer;
  }
  a:hover {
    text-decoration: none;
    color: darken($secondary-color, 15%);
    cursor: pointer;
  }
  .hasTabBar {
    padding-bottom: 60px;
    overflow-x: hidden;
  }

  .darkTransparentOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: $dark-transparent-overlay;
    opacity: 0.87;
  }

  .transparentOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #2a3742;
    opacity: 0.55;
    border-radius: 3px;
  }
  .absolute {
    position: absolute;
  }

  .text-centered {
    text-align: center;
  }

  .fullWidthLine {
    position: static;
    display: inline-block;
    background: $dark-font-color;
    width: 100%;
    height: 1px;
  }

  .loading-container {
    position: relative;

    &.min-height {
      min-height: 150px;
    }

    .spinner-container {
      display: none;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.loading {
      .spinner-container {
        background-color: $primary-color;
      }

      &.blur {
        .spinner-container {
          background-color: rgba(210, 210, 210, 0.25);
        }

        .loading-content-container {
          filter: blur(15px);
          overflow: hidden;
        }
      }
    }
  }
}

.dashed-border {
  border-top: 1px dashed $light-gray;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.description-editor {
  border: 1px solid $light-gray;
  padding: 10px;
}

.description-toolbar {
  border: 1px solid $light-gray;
}

.rdw-image-modal-btn {
  background-color: $primary-color;
  border-radius: 500vh;

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    background-color: $light-font-color;
  }
}

.rdw-link-modal {
  height: 250px;

  .rdw-link-modal-btn {
    background-color: $primary-color;
    border-radius: 500vh;

    &:hover {
      box-shadow: none;
    }

    &:disabled {
      background-color: $light-font-color;
    }
  }
}

.rdw-embedded-modal {
  height: 250px;

  .rdw-embedded-modal-btn {
    background-color: $primary-color;
    border-radius: 500vh;

    &:hover {
      box-shadow: none;
    }

    &:disabled {
      background-color: $light-font-color;
    }
  }
}

.Select-control {
  border-radius: 500px !important;
  overflow: hidden;
}

.Select-menu-outer {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;

  -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
}

.rt-th {
  background-color: #e8e8e8;
}

.modal-close-btn {
  &:focus {
    outline: none !important;
    background-color: transparent !important;
  }
}

.close {
  font-size: 30px !important;
  font-weight: 500;
  &:focus {
    outline: none !important;
    background-color: transparent !important;
  }
}

.modal-header {
  padding: 0px !important;
  padding-bottom: 8px !important;
  h5 {
    font-size: 20px !important;
  }
}

.round-image-wrapper {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 70px;
  width: 70px;
  background-position: center;
  flex-shrink: 0;

  .round-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.large-round-image-wrapper {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 100px;
  width: 100px;
  background-position: center;
  flex-shrink: 0;

  .round-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.medium-round-image-wrapper {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;

  .round-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.smaller-round-image-wrapper {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 35px;
  width: 35px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .round-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.small-round-image-wrapper {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .round-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.animation-shrink {
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(0.95);
  }
}

// input {
//   border-radius: 500px !important;
// }

.required-text {
  color: $input-error-color;
  font-size: 14px;
}

.disabled-row {
  &:hover {
    cursor: default !important;
  }
}

label {
  margin-left: 0px;
}

textarea {
  border-radius: 0px !important;
  border: 1px solid $light-gray !important;
  color: $secondary-color !important;
  font-weight: 550;
  font-size: 18px;

  &:focus {
    border-bottom: 2px solid $primary-color !important;
  }

  &:disabled {
    border-bottom: 1px solid lighten($light-navy, 15%) !important;
    color: lighten($secondary-color, 15%) !important;
    cursor: not-allowed;
  }
}

// select {
//   border-radius: 500px !important;
//   -webkit-appearance: none;
//   -webkit-border-radius: 0px;
// }

input {
  padding: 5px;
  padding-left: 15px;
  border: none !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 1px solid $light-gray !important;
  color: $dark-navy !important;
  font-weight: 550;
  font-size: 18px;

  &:focus {
    border-bottom: 2px solid $primary-color !important;
  }

  &:disabled {
    border-bottom: 1px solid lighten($light-navy, 15%) !important;
    color: lighten($secondary-color, 15%) !important;
    cursor: not-allowed;
  }
}

select {
  padding: 5px;
  padding-left: 15px;
  border: none;
  background-color: transparent !important;
  border-radius: 0px !important;
  color: $secondary-color;
  font-weight: 550;
  font-size: 16px;

  &:focus {
    border-bottom: 2px solid $primary-color !important;
  }

  &:disabled {
    border-bottom: 1px solid lighten($light-navy, 15%) !important;
    color: $light-gray !important;
    cursor: not-allowed;
  }
}

::placeholder {
  color: $light-gray !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $light-gray !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $light-gray !important;
}

.chartjs-render-monitor {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.popover {
  border-radius: 8px !important;
  overflow: hidden !important;
  z-index: 9999;
  -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
}

.primary-background {
  background-color: #e8e8e8 !important;
  color: $primary-color !important;
}

#activityWrapper {
  border: 1px solid #f5f5f5 !important;
  border-radius: 8px;
  -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
}

th {
  color: $primary-color !important;
}

.center-cropped {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.fullWidthLine {
  position: static;
  display: inline-block;
  background: $dark-font-color;
  width: 100%;
  height: 1px;
}

.text-divider {
  height: 1px;
  background-color: #e0e0e0;
}

.loading-bar {
  background-color: $secondary-color;
  height: 3px;
  z-index: 99999;
  position: absolute;
}

.navbar {
  width: 90% !important;
  background-color: white !important;
}
.navbar-nav {
  width: 100% !important;
  -webkit-box-shadow: 5px 10px 25px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 10px 25px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 10px 25px 10px rgba(0, 0, 0, 0.4);
}
.navbar-toggleable {
  padding: 0px !important;
}
.nav-item {
  border-bottom: 1px solid $light-gray;
  width: 100%;

  .nav-link {
    font-size: 18px !important;
    padding-left: 20px !important;
    color: $primary-color !important;
  }
}

.badge-success {
  background-color: $primary-teal !important;
  color: $white-font-color !important;
}

.badge-active {
  background-color: $primary-color !important;
  color: $white-font-color !important;
}

.badge-warning {
  background-color: $secondary-color !important;
  color: $white-font-color !important;
}

.badge-danger {
  color: $white-font-color !important;
}

.light-loading-spinner {
  color: $white-font-color;
  font-size: 18px;
  margin-right: 5px;
}

.loading-spinner {
  color: $light-font-color;
  font-size: 18px;
}

.image-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(43, 56, 67, 0),
    rgba(43, 56, 67, 1)
  );
}

.previous-step-button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: $dark-font-color;

  &:focus {
    background-color: transparent;
  }
}

.pdf-pagination {
  i {
    &:hover {
      cursor: pointer;
    }
  }
}

.Toastify__toast-container {
  @media (max-width: 576px) {
    width: 98vw;
    bottom: 2vw;
    left: 1vw;
  }

  width: 400px;

  .Toastify__toast {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    word-break: break-word;
  }

  .Toastify__toast--success {
    border: 2px solid #33D9B2;
  }

  .Toastify__toast--error {
    border: 2px solid #D2042D;
  }

  .Toastify__toast--info {
    border: 1px solid $primary-orange;
  }
}

.btn {
  border-radius: 0px !important;
}

.btn-delete {
  background-color: transparent;
  color: $light-red !important;
  font-size: 14px;

  i {
    margin-right: 5px;
  }

  &:hover {
    color: $dark-red !important;
    text-decoration: none;
  }

  &:focus {
    background-color: transparent;
    outline: none !important;
    border: none !important;
  }
}

.btn-link-preview {
  background-color: transparent;
  color: $light-font-color !important;
  font-size: 14px;
  text-decoration: underline;
  text-decoration-style: dotted;

  &:hover {
    color: darken($light-font-color, 25%) !important;
    text-decoration: none;
  }

  &:focus {
    background-color: transparent;
    outline: none !important;
    border: none !important;
  }
}

.btn-tertiary {
  background-color: $primary-navy !important;
  border-radius: 500px !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
}

.btn-default {
  color: white !important;
  border: none !important;
  border-radius: 500px !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

  background-image: linear-gradient(
    to right,
    $primary-orange,
    $secondary-color
  ) !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-block {
  color: white !important;
  border: none !important;
  border-radius: 500px !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

  background-image: linear-gradient(
    to right,
    $primary-orange,
    $secondary-color
  ) !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-success {
  border-radius: 500px !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  border: none !important;

  background-image: linear-gradient(
    to right,
    $light-teal,
    $primary-teal
  ) !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-gray {
  border-radius: 500px !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  border: none !important;

  background-image: linear-gradient(
    to right,
    $light-gray,
    $light-font-color
  ) !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-dark {
  border-radius: 500px !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

  background-image: linear-gradient(
    to right,
    $light-navy,
    $primary-color
  ) !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-link {
  color: $secondary-color;

  &:hover {
    color: darken($secondary-color, 15%);
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.btn-dark-link {
  color: $primary-color;
  background-color: transparent;
  border: none;

  &:hover {
    color: lighten($primary-color, 25%);
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
    background-color: transparent;
    border: none;
  }
}

.btn-warning {
  color: white !important;
  border-radius: 500px !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  border: none !important;

  background-image: linear-gradient(to right, $light-red, $dark-red) !important;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-op36 {
  //@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  @include button-variant(#fff, $secondary-color, $secondary-color-alt);
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

  background-color: $secondary-color;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-primary {
  border-radius: 500px !important;
  border: none !important;

  background-color: $secondary-color;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }

  &:disabled {
    background: #484b4d;
    border-color: #484b4d;
    transform: none;
  }

  &:disabled:hover {
    background: #484b4d;
    border-color: #484b4d;
    transform: none;
  }
}

.btn-outline--white {
  border-radius: 500vh !important;
  border: 1px solid $white-font-color !important;
  background-color: transparent;
  padding: 5px 15px;
  color: $white-font-color;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
    color: $white-font-color;
  }
}

.btn-outline--orange {
  border-radius: 6px !important;
  border: 2px solid $primary-orange !important;
  background-color: transparent !important;
  color: $primary-orange !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 10px 10px -10px rgb(0 0 0 / 50%);

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
}

.btn-unselected {
  border-radius: 500px !important;
  border: none !important;
  color: $secondary-color;
  background-color: white;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }

  &:disabled {
    background: #484b4d;
    border-color: #484b4d;
  }

  &:disabled:hover {
    background: #484b4d;
    border-color: #484b4d;
  }
}

.btn-white {
  border-radius: 500px !important;
  border: none !important;
  color: white !important;
  -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

  transition: all 0.2s ease-in-out;

  background-image: linear-gradient(
    to right,
    $primary-orange,
    $secondary-color
  ) !important;

  &:hover {
    transform: scale(0.95);
  }

  &:focus {
    outline: none !important;
  }
}

.ReactModal__Content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: none !important;
  overflow: hidden;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
}

.disabled-card {
  cursor: not-allowed;
}

.card {
  border: none !important;
  border-radius: 8px !important;
  -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
}

.card-header-text {
  font-size: 16px;
  color: $light-font-color;
  font-weight: light;
}

.card-no-overflow {
  overflow: visible !important;
}

.ReactModal__Overlay {
  background-color: $dark-transparent-overlay !important;
}

.actionButton {
  border-radius: 0px !important;
}

// .ReactTabs__TabList { // Replaced with the ones below. If no complains, remove.
//   background-color: $background-color !important;

//   .ReactTabs__Tab {
//     background-color: $background-color;
//   }

//   .ReactTabs__Tab[aria-selected="true"] {
//     background-color: $background-color !important;
//   }
// }

.react-tabs__tab-list {
  background-color: $background-color !important;

  .react-tabs__tab {
    background-color: $background-color;
  }

  .react-tabs__tab[aria-selected="true"] {
    background-color: $background-color !important;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.search-container {
  display: flex;
  justify-content: center;
  flex-direction: row;

  .search-icon {
    margin-top: 6px;
    font-size: 40px;
    color: $placeholder-gray;
  }

  .search-input {
    margin-left: 8px;
    margin-right: 15px;
    margin-bottom: 20px;
    // border-radius: 500px;
    outline: none;
    border-color: none;
    width: 90%;

    input {
      width: 100% !important;
      border: none !important;
      font-weight: 550;
      font-size: 40px;
      color: $light-font-color;
      background-color: transparent;
      border-radius: 0px !important;
      border-bottom: 1px solid $light-font-color !important;

      transition: border-bottom 0.2s ease-in;

      &:focus {
        border-bottom: 3px solid $primary-color !important;
        color: $primary-color;
      }
    }
  }
}

/* xs < 768 */
@media screen and (max-width: 767px) {
  .search-container {
    justify-content: center;
    .search-icon {
      font-size: 30px !important;
    }
  }

  .search-input {
    margin-right: 0px;

    input {
      font-size: 30px !important;
      text-align: center;
    }
  }
}


///////////////////////////////////////////////////////////////////////////
////////////////////////////// New stuff (start) //////////////////////////////
///////////////////////////////////////////////////////////////////////////

// Commented out so I don't mess up other buttons yet
// button {
//   background-color: $primary-orange;
//   color: white;
//   box-shadow: $pressable-elem-drop-shadow;
//   transition: all 0.2s ease-in-out;
// }
//
// button:hover {
//   transform: scale(0.95);
//   transition: all 0.2s ease-in-out;
// }

// .btn-rectangular buttons are the long, skinny buttons, not clickable
// cards
.btn-rectangular {
  // background-color: $primary-orange;
  // color: white;
  // box-shadow: $pressable-elem-drop-shadow;
  transition: all 0.2s ease-in-out;
  border-radius: 50px;
  min-width: 50px;
  padding: 10px;
  box-sizing: border-box;
  line-height: 1;
  border: 0;
  width: 100%;
}

.btn-rectangular:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  transition: none;
}

// Orange and fully-rounded
.btn-rectangular-main-positive {
  background-color: $primary-orange;
  color: white;
  box-shadow: $pressable-elem-drop-shadow;
}

// Orange and not fully-rounded
.btn-rectangular-secondary-positive {
  background-color: $primary-orange;
  border-radius: $card-corner-radius;
  box-shadow: $pressable-elem-drop-shadow;
}

// White and invisible BG
.btn-rectangular-main-negative {
  background-color: white;
  color: $primary-orange;
  box-shadow: none;
}

// Button that is just bold orange text
.no-bg-text-btn {
  color: $primary-orange;
  font-size: $heading-3-size;
  font-weight: $bold-font-weight;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0px;
  transition: all 0.2s ease-in-out;

  &:hover {
    // color: darken($primary-orange, 20%);
    cursor: pointer;
  }
}

// X in corner of modals and back arrow buttons
.x-modal-close-btn,
.back-arrow-btn {
  background-color: white;
  padding: 0;
  border: none;
  margin: 10px -10px 10px 0px !important;
  width: 36px;
  transition: all 0.2s ease-in-out;

  i {
    font-size: $heading-1-size;
    color: $dark-navy;
  }

  &:active {
    background-color: white;
  }
}

.x-modal-close-btn {
  margin: auto;
  margin-right: 0px;
}

.back-arrow-btn {
  i {
    color: $light-navy;
  }
}

// Cards that are clickable - they're essentially really big buttons
.card-btn {
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

.btn-rectangular:hover,
.btn-rectangular-main-positive:hover,
.btn-rectangular-secondary-positive:hover,
.btn-rectangular-main-negative:hover,
.no-bg-text-btn:hover,
.x-modal-close-btn:hover,
.back-arrow-btn:hover .card-btn:hover {
  transform: scale(0.95);
  transition: all 0.2s ease-in-out;
}

.btn-rectangular-main-positive:hover,
.btn-rectangular-secondary-positive:hover {
  color: white;
}

// Style by Jasmine (2019.12.16)
// This will be the new style for modal content
// The content bg will be transparent, but the overlay will be
// dark navy at 75% (rgba(29, 41, 51, 0.75))
.ReactModal__Content.ReactModal__Content--after-open.customModal {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible; // So box-shadow isn't clipped on smaller screens
}

///////////////////////////////////////////////////////////////////////////
////////////////////////////// New stuff (end) //////////////////////////////
///////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 640px) {
  .modal-header {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;

    .modal-title {
      margin-right: 0px !important;
    }

    h5 {
      font-size: 25px;
    }
  }

  .ReactModal__Content {
    width: 100%;
    margin: 0;
    position: static !important;
  }
}

/* sm */
@media screen and (max-width: 768px) {
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ReactModal__Content {
    width: 100%;
    margin: 0;
  }
}
