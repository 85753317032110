@import "../../assets/styles/globals";

#NoCommunityModal {
  $user-color: #2b3843;
  position: fixed;
  z-index: 1000010;
  height: 90%;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 480px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  overflow-y: auto;
  .exit {
    padding: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  label,
  .req-form-family-count {
    display: block;
    font-size: 14px;
    font-weight: 550;
    margin-bottom: 2px;
    margin-top: 15px;
    color: $user-color;
    cursor: default;
    p {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  .req-form-title {
    font-size: 16px;
    font-weight: 600;
  }
  .req-form-intro {
    font-size: 14px;
    margin-bottom: 0;
  }
  .split-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .phone-area {
      width: 49%;
    }
    .location-area {
      width: 49%;
    }
  }
  .family-count-box {
    display: flex;
    justify-content: space-evenly;
    .radio-container {
      .radio-outer {
        height: 20px;
        width: 20px;
        border: 2px solid $user-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .radio-inner {
          height: 12px;
          // width: 80%;
          flex-basis: 12px;
          flex-grow: 0;
          flex-shrink: 0;
          background: $user-color;
          border-radius: 50%;
        }
      }
      .radio-label {
        text-align: center;
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }
  .checkbox-area {
    display: flex;
    width: 100%;
    .checkbox-container {
      width: 50%;
      .checkbox-title {
        font-weight: 550;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 5px;
      }
      .check-row {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        .check-box {
          height: 20px;
          width: 20px;
          border: 2px solid $user-color;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
        }
        .check-label {
          margin: 0;
          font-size: 13px;
        }
      }
    }
  }
  input {
    width: 100%;
    border: none !important;
    border-bottom: 2px solid $user-color !important;
    color: $user-color !important;
    font-size: 15px;
    padding: 0 5px;
    font-weight: 450;
  }

  textarea {
    width: 100%;
    height: 55px;
    resize: none;
    overflow-y: auto;
    font-size: 15px;
    color: $user-color !important;
    font-weight: 450;
    border: 2px solid #2b3843 !important;
    background: #f4f4f5;
  }

  .invalid-email-note {
    border: 2px solid #fc6d81;
    padding: 5px;
    text-align: center;
    color: #fc6d81;
    background: #ffd8dd;
  }

  button {
    width: 80%;
    display: block;
    background: $primary-orange;
    border-radius: 500px;
    box-shadow: 4px 4px 4px #555;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    &:hover {
      position: relative;
      bottom: -2px;
      right: -2px;
      box-shadow: 2px 2px 2px #555;
      color: #f4f4f5;
    }
  }

  .red-text {
    color: red;
  }
  .red-line {
    border-bottom: 2px solid red !important;
  }
  .red-border {
    border: 2px solid red !important;
  }

  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .check {
      font-size: 70px;
      color: #12a482;
    }
    .success-title {
      font-size: 36px;
      color: #12a482;
    }
    .success-note {
      text-align: center;
    }
  }
  @media screen and (max-width: 830px) {
    width: 95%;
  }
}
