#EducationPreviewModal {
  padding-right: 365px;
  position: relative;
  height: calc(100vh - 215px);
  .preview-title {
    font-weight: bold;
    font-size: 2.5rem;
  }
  .preview-sidebar {
    position: absolute;
    top: 15px;
    right: 0;
    width: 340px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    overflow: hidden;
    .preview-video-container {
      position: relative;
      flex: 0 0 191px;
    }
    .price {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1;
    }
    .sub-description {
      margin-top: 15px;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      padding: 8px;
      .sub-description-text {
        margin: 0;
        line-height: 1.25;
        font-size: 14px;
        color: #c7c7c7;
      }
    }
  }
  #EducationLesson,
  #EducationSection {
    button:hover {
      cursor: default;
      background-color: #f7f7f7;
    }
  }
}

@media screen and (max-width: 840px) {
  #EducationPreviewModal {
    padding-right: 0;
    flex-direction: column;
    .preview-sidebar {
      position: static;
    }
  }
}
