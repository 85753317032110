#AddStudentsModal {
  .add-student-card {
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;

    margin-bottom: 10px;
    padding: 18px;

    p {
      margin: 0;
      font-size: 18px;
    }
    .image-name-container {
      flex: 1 1;
      .student-pic {
        height: 70px;
        flex: 0 0 70px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .division-icon {
      height: 70px;
      flex: 0 0 70px;
      background-size: cover;
      background-position: center;
    }
  }
  .clickable {
    box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
    transform: scale(1);
    transition: transform 0.2s;
    &:hover {
      transform: scale(0.95);
      cursor: pointer;
    }
  }
  .unclickable {
    background: #ddd;
    color: #bbb;
  }
  .checked {
    background: yellow;
    transform: scale(0.95);
  }
}
