@import "../../../assets/styles/globals.scss";

#RegistrationStepperContainer {
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  //   border-top: 1px solid $light-navy;
  //   border-bottom: 1px solid $light-navy;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid $light-font-color;
  padding-top: 20px;
  padding-bottom: 20px;
  .registration-step-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    i {
      font-size: 22px;
    }

    .registration-step-name {
      color: $light-font-color;
      font-size: 12px !important;
      margin-bottom: 2px;
    }

    .active-step-name {
      color: $secondary-color;
    }

    .inactive-step {
      color: $light-font-color;
    }

    .active-step {
      color: $secondary-color;
    }

    .registration-step-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .registration-step-number {
        position: absolute;
        font-size: 12px;
        font-weight: 550;
        margin: 0px;
      }
    }
  }
}
