@import "../../globals";

.op36-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .op36-signin-logo {
    align-self: center;
    width: 100px;
    height: 100px;
  }
}

.signInPage {
  position: fixed;
  background-image: url("../../../../assets/images/auth/Op36Registration.png");
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.signin-page-logo-bg {
  background-color: $primary-color;
}

.forgot-credential-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .text-separator {
    height: 3px;
    width: 3px;
    background-color: $primary-color;
    border-radius: 50%;
    margin: 0px 5px;
  }

  .forgot-text-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px;

    .forgot-text-link {
      font-size: 13px;
      color: $primary-color !important;
    }
  }
}

label {
  font-size: 14px;
  color: $primary-color;
  margin-left: 10px;
}
#userSignIn {
  img {
    padding-top: 5vh;
    display: block;
    width: 70%;
    max-width: 400px;
    margin: auto;
  }
  h1 {
    padding-top: 5vh;
    position: relative;
    font-size: 27px;
    color: $white-font-color;
    text-align: center;
  }
  h1:before {
    content: "";
    position: absolute;
    margin-left: 10px;
    width: 70px;
    height: 1px;
    bottom: -3px;
    border-bottom: 3px solid;
    border-color: $secondary-color;
  }

  .underline {
    width: 100px;
    height: 3px;
    color: $secondary-color;
  }
  p {
    padding-top: 20px;
    color: $white-font-color;
    margin: auto;
    text-align: center;
    line-height: 22px;
    width: 70%;
    max-width: 400px;
  }
  .form-container {
    padding-top: 5vh;
  }
  .first-btn {
    margin-top: 10px;
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white-font-color;
    &.first-btn {
      margin-top: 2.5vh;
    }
  }
  #forgot-password-button {
    background-color: #eee;
    border-color: #eee;
    color: #999;
  }
  .first-btn:active {
    background-color: $secondary-color-alt;
    border-color: $secondary-color-alt;
  }
  .form-container {
    padding-left: 10%;
    padding-right: 10%;
    .text-input {
      padding-bottom: 10px;
    }
    #formErrors {
      p {
        text-align: left;
        width: 100%;
        max-width: 100%;
        span {
          padding-right: 10px;
          color: $error-font-color;
        }
      }
    }
  }
}

.signin-loader-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .signin-loader-icon {
    color: $primary-color;
  }

  .signin-loader-text {
    color: $primary-color;
  }
}
