@import "../../assets/styles/globals";

.community-top-header {
  height: 60px;
  background: #1d2933;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 25px 0 45px;
  padding: 0 18px;
  position: relative;
  z-index: 100004;

  .logo-container {
    height: 45px;
    // width: 300px;
  }

  .nav-button-container {
    display: flex;
    align-items: center;

    .home-link {
      margin-right: 10px;
      color: #fff;
      transition: color 1s;
      &:hover {
        color: #eee;
      }
    }

    .nav-login-button {
      border-radius: 500px;
      padding: 5px 30px;
      background: $primary-orange;
      border: none;
      transition: color 1s;

      &:hover {
        color: #1d2933;
      }
    }

    @media screen and (max-width: 830px) {
      display: none;
    }
  }

  @media screen and (max-width: 830px) {
    justify-content: center;
  }
}
