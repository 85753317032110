#MapSidebar {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 830px) {
    height: 100%;
  }
  .top-search-area {
    display: flex;
    flex-wrap: none;
    width: 100%;
    flex-direction: column;
    padding: 5px 5% 15px 5%;
    background: #fff;
    box-shadow: 2px 2px 2px -2px #777;
    .search-title {
      font-weight: 550;
      font-size: 18px;
      margin-bottom: 10px;
    }
    i {
      align-self: center;
      color: #2b3843;
    }
    .search-input {
      border: none !important;
      border-bottom: 2px solid #2b3843 !important;
      font-weight: normal;
      font-size: 16px;
      color: #2b3843 !important;
      padding-left: 10px;
      margin-bottom: 10px;
      width: 100%;
    }
    .filter-title-bar {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .filter-title {
        font-weight: 550;
        &:hover {
          cursor: pointer;
        }
        .fa-chevron-down {
          transform: rotate(0deg);
          transition: transform 0.2s;
        }
        .flip {
          transform: rotate(180deg);
        }
      }

      .location-types-modal-link {
        border: none;
        font-size: 14px;
      }
    }
    .filter-box-container {
      display: flex;
      align-items: center;
      margin: 2px 0;
      .filter-check-box {
        height: 25px;
        // width: 20px;
        flex-basis: 25px;
        flex-grow: 0;
        flex-shrink: 0;
        border: 2px solid #2b3843;
        border-radius: 5px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
        .fa-check {
          font-size: 20px;
        }
      }
      .filter-description-container {
        display: flex;
        align-items: center;
        background: #5f6f7c;
        border-radius: 8px;
        width: 100%; // So it stretches full width in tablet mode
        .filter-badge {
          height: 45px;
          flex-basis: 42px;
          flex-shrink: 0;
          flex-grow: 0;
          // width: 65px;
          margin: 8px;
          filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
        }
        .filter-description-box {
          padding-right: 5px;
          padding-bottom: 6px;
          p {
            margin: 0;
            color: #fff;
          }
          .filter-description-title {
            // font-weight: 550;
          }
          .filter-description {
            line-height: 1.1;
            font-size: 11px;
            font-weight: 200;
          }
        }
      }
    }
  }

  .community-content-area {
    height: calc(100% - 136px);
    overflow-y: auto;

    padding: 5px 18px 5px 15px;
    width: 100%;
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
      margin-left: 10px;
    }
    &::-webkit-scrollbar-thumb {
      // background-color: red;
      // background-image: linear-gradient(#f68a22, #7dc1f5, #adfa96);
      background-color: #1d2933;
      border-radius: 10px;
    }
    .load-cog {
      font-size: 60px;
      margin-top: 40px;
    }
    .invalid-location {
      color: #ccc;
      i {
        font-size: 60px;
        margin-top: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 830px) {
      padding: 0 18px 0 15px;
      border-bottom: 1px solid #2b3843;
    }
  }
}
