@import '../../../assets/styles/globals.scss';

.coachDashboardNew{
  margin-left: -15px;
  margin-right: -15px;
  height: 100%;

  .tabs {
    margin-left: -15px;
    margin-right: -15px;
  }

  .tabList {
    margin-left: -40px;
    margin-right: -15px;
    margin-bottom: 0px;
    height: 40px;
    background-color: white;
    border-bottom: 2px solid $light-gray !important;

    .tab{
      margin-top: 0px;
      text-transform: none;
      background-color: transparent;
      padding-left: 80px;
      padding-right: 80px;
      font-size: 14px;
      height: 40px;
      font-weight: bold;
      color: black;
    }

    .activeTab{
      border-bottom: 4px solid $secondary-color;
      height: 40px;
      color: $secondary-color;
      background: transparent !important;
    }
  }
}