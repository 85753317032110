@import '../../globals';

$objectPlaceholder: url('../../../images/mock/classPlaceholder.png');


#ParentClassesPage{
	.class-promo-widget{
		background-color: $primary-color;
		// background: $objectPlaceholder;		
		// background-size: cover;
		min-height: 20vh;
		* .badge{
			font-weight: 500;
			font-size: 16px;
		}
		.overlay-5{
			padding:10vh 15px;
		}
	}
	* .card{
		border:none;
		* .card-title{
			min-height: 100px;
		}
	}

}

.join-program-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2em;
}

.join-program-listitem {
  width: 20%;
  margin: 1em 0;
  input {
    margin-right: .5em;
  }
}

// Inside a modal
.enrolledFamilyMember, .pendingFamilyMember{
  opacity: .5;

}

// React Modal Override.
// Consider adding this to a global stylesheet.
// .ReactModal__Content{
// 	background-color: transparent !important;
// 	border-color: transparent !important;
// }
button.close{
	font-size: 70px;
}
.ReactModal__Overlay{
	background-color: rgba(255, 255, 255, 0.9);
}
