@import "../../assets/styles/globals";

#NotificationsContainer {
  height: 250px;
  overflow-y: scroll;

  .notifications-wrapper {
    padding: 15px;

    .notifications-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-content: center;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid $light-font-color;

      .notifications-refresh-btn {
        font-size: 14px;
      }

      .notifications-clear-btn {
        color: $error-font-color;
        font-size: 11px;
        border-radius: 500vh !important;

        &:hover {
          color: darken($error-font-color, 15%);
        }
      }
    }
  }
}
.notifications-empty-text {
  margin: 0px;
  color: $light-font-color;
  text-align: center;
}
