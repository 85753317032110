@import "../../../assets/styles/globals";

#ScheduleSessionForm {
  .form-title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .form-field {
    display: flex;
    align-items: center;
    color: $placeholder-gray;

    .icon-wrapper {
      width: 24px;
    }
  }

  .form-label {
    color: $dark-navy !important;
    font-size: 16px;
    font-weight: 700;
  }

  .character-counter {
    color: $light-navy;
    font-size: 14px;
  }

  .delete-button {
    color:$primary-orange;
    cursor: pointer;
  }

  .delimiter {
    margin-left: -100px;
    margin-right: -10px;
    z-index: 999;
  }

  .p-0 {
    padding: 0;
  }

  .date-time {
    min-width: 200px;
  }
}
