@import '../../../assets/styles/globals.scss';

.loadingAnimation {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: lighten($light-navy, 45%);
  }

  100% {
    background-color: lighten($light-navy, 40%);
  }
}

.container {
  position: relative;
  height: 280px;
  width: 97%;
  padding: 0 10px;
}

.card {
  width: 100%;
  height: 245px;
  padding: 35px 30px;
  background-color: lighten($light-navy, 55%);
  border-radius: 8px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.titleSection {
  display: flex;
  flex-direction: column;
  width: 65%;
  gap: 10px;
}

.skeletonAvatar {
  height: 46px;
  width: 46px;
  border-radius: 50%;
}

.skeletonTitle {
  border-radius: 4px;
  width: 100%;
  height: 24px;
}

.skeletonSubTitle {
  border-radius: 4px;
  width: 70%;
  height: 16px;
}

.skeletonShortText {
  width: 70%;
  height: 20px;
  border-radius: 4px;
}

.skeletonText {
  width: 90%;
  height: 20px;
  border-radius: 4px;
}
