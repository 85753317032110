@import '../../../assets/styles/globals.scss';

.student-picker-wrapper {
  .check-box {
    border: 2px solid $primary-orange;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .checked {
    background-color: $primary-orange;
  }

  .student-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .student-date {
    color: $light-navy;
  }

  .student-data {
    border-bottom: 1px solid;
    border-color: $light-gray;
  }

  .student-level {
    font-size: 25px;
  }
}
