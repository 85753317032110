@import "../../assets/styles/globals";

.series-card {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid;
  border-color: $light-gray;
  cursor: pointer;
  height: 90px;

  &:hover {
    background-color: $off-white;
  }

  .draft-label {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-navy;
    border-radius: 500vh;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    .edit-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .series-button {
    background-color: transparent;
    border: none;
  }

  .date-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px;
    font-size: 12px;
    .day-number {
      font-weight: 700;
      font-size: 24px;
      color: $dark-navy;
    }

    .month-name {
      font-weight: 700;
      color: $dark-navy;
    }

    .day-name {
      color: $light-navy
    }
  }

  .series-info {
    font-size: 16px;
    font-weight: 800;

    .events-count-label {
      width: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-orange;
      border-radius: 500vh;
      padding: 2px 12px;
      .label-text {
        color: $white-font-color;
        margin: 0px;
        font-size: 14px;
        font-weight: 600;
      }
    }


    .horizontal-line {
      width: 20px;
      background: $light-gray;
      height: 2px;
      margin-inline: 5px;
    }

    .vertical-line {
      width: 2px;
      background: $light-gray;
      height: 80%;
      margin-inline: 20px;
    }
  }

  .expand-view-col {
    font-size: 14px;
    font-weight: 800;
    .golfers-count {
      font-size: 24px;
    }
  }

  .next-event-col {
    font-size: 14px;
    font-weight: 800;
  }

  .actions-col {
    margin-block: auto;
    text-align: center;
    max-width: 60px;
  }

  .vl {
    border-left: 2px solid $placeholder-gray;
    height: 60px;
    margin: auto 0;
    margin-left: 18px;
    padding-left: 18px;
  }

}

.first-child {
  border-top: 1px solid;
  border-color: $light-gray;
}
