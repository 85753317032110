@import "../../../../assets/styles/globals";

#SimpleEducationSection {
  width: 95%;
  margin: auto;
  margin-bottom: 15px;

  h5 {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 550;
    margin-left: 10px;
  }

  p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 14px;
  }

  .section-container {
    overflow: hidden;
    background-color: lighten($light-gray, 10%);
    border: 1px solid $light-font-color !important;
    .section-button {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      text-align: left;
      color: $primary-color;

      .section-completed-count-wrapper {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 0px;
        color: $primary-color;
      }

      .completed-section {
        font-weight: 600;
        font-size: 16px;
        color: $secondary-color;
      }
    }
  }
}
