@import "../../../assets/styles/globals";

.container {
  padding: 2px 40px;

  label {
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    color: $dark-navy;
  }
}

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  color: $primary-navy;
  margin-bottom: 76px;
}


.accountInputContainer,
.accountInputContainerPhone {
  padding: 5px 15px !important;

  input {
    color: $light-navy !important;
  }
}

.accountInputContainerPhone {
  div>div>button {
    margin-top: 3px;
    z-index: 1 !important;
  }
}

.genderInputWrapper {
  color: $light-navy !important;
}

label.inputIcon {
  position: absolute;
  right: 25px;
  color: $light-navy;
}

@media (min-width: 1400px) {
  .container {
    padding: 2px 80px;
  }
}