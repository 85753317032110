@import "../../../assets/styles/globals";

#EducationDetailPage {
  .education-detail-sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;

    .education-detail-image-wrapper {
      height: 150px;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 8px;

      // img {
      //   width: 100%;
      // }
    }

    .education-detail-sidebar-button-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      button {
        text-align: right;

        &:active {
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }
      }

      .sidebar-active {
        background-color: lighten($light-gray, 10%);
      }
    }
  }

  .education-detail-info-container {
    p {
      font-size: 14px;
    }

    .progress-bar-text {
      font-size: 12px;
    }

    h6 {
      border-top: 1px solid $light-gray;
      padding-top: 15px;
      margin-top: 15px;
      font-size: 18px;
      font-weight: 550;
      color: $primary-color;
    }

    .education-detail-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .education-detail-top-header {
        display: flex;
        flex-direction: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .education-detail-title {
          h5 {
            font-size: 25px;
            font-weight: 550;
            color: $primary-color;
            margin: 0px;
          }

          p {
            font-size: 14px;
            color: $light-font-color;
            margin: 0px;
          }
        }

        .education-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          button {
            font-size: 14px;
          }
        }
      }
    }
  }
}
