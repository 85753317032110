@import "../../assets/styles/globals";

#SignInWeb {
  height: 100vh;
}

#SignInContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .signin-wrapper {
    margin: auto;
    width: 100%;
    height: 650px;

    .btn-primary {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .btn-orange {
      background-color: $light-navy;
    }

    .signin-card {
      overflow: hidden !important;
      height: 100%;
      -webkit-box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
      background-color: $white-font-color;
      border-radius: 8px;

      .signin-inner-wrapper {
        height: 100%;
        .signin-left-container {
          height: 100%;
          .signin-form-container {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            h4 {
              font-weight: 550;
              color: $secondary-color;
              text-align: center;
              text-transform: uppercase;
            }

            p {
              text-align: center;
              font-size: 14px;
              color: $primary-color;
              margin-bottom: 22px;
            }
          }

          .no-account-container {
            text-align: center;

            .no-account-question-label {
              margin-bottom: 5px;
              font-size: 16px;
              color: $light-navy;
            }

            .sign-up-here-link {
              background-color: $primary-orange;
              color: white;
            }
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: $placeholder-gray;
            margin: 18px 0;
          }
        }

        .signin-right-container {
          height: 100%;
          background-color: $light-blue;
        }
      }
    }
  }
}

/* sm */
@media screen and (max-width: 768px) {
  .signin-card,
  #SignInContainer {
    width: 100%;
    padding: 0px !important;
  }
}
