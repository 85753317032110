@import "../../../../assets/styles/globals";

#RegistrationCompletedSimple {
  height: 100%;

  @media (min-width: 768px) {
    height: auto;
  }

  .final-step-header {
    width: 80%;
    margin-bottom: 0.8em;
    color: $primary-orange;
    font-weight: bold;

    @media (min-width: 768px) {
      color: inherit;
    }
  }

  .app-mock-img {
    height: 150px;

    @media (min-width: 768px) {
      height: auto;
      width: 50%;
    }
  }

  .app-mock-background-img {
    @media (min-width: 768px) {
      width: 50%;
      margin-right: -30px;
    }
  }

  .qr-code-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 169px;
    width: 169px;
    border: 5px solid $primary-orange;
    border-radius: 10px;
  }

  .app-facilities-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0.85em;
    font-weight: 600;
    color: $light-navy;

    @media (min-width: 768px) {
      font-size: 1em;
      list-style-type: disc;
      padding-left: 1.25em;

      li::marker {
        color: $primary-orange !important;
        font-size: 1.5em;
        line-height: 0.25em;
      }
    }

    li {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
