@import "../../../assets/styles/globals";

.no-sessions-placeholder {
  background-color: $off-white;
  color: $light-navy;
  margin-bottom: 18px;
  padding: 18px;
  border-top: 2px solid $placeholder-gray;
  border-bottom: 2px solid $placeholder-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 700;
    font-size: 18px;
  }
}

.hl {
  position: static;
  display: inline-block;
  background: $placeholder-gray;
  width: 100%;
  height: 2px;
}
