@import "../../../assets/styles/globals";

.react-datepicker {
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    border-radius: 1.3rem;
    background-color: $primary-orange;
  }

  .react-datepicker__navigation {
    &:hover, :focus, :active {
      background: none;
    }
  }

  .react-datepicker__header {
    background: none;
    border-bottom: none;
    padding: 0;
    padding-top: 5px;
  }

  .react-datepicker__day-names {
    font-weight: 600;
  }
  .react-datepicker__day, .react-datepicker__day-name {
    margin: 0;
    width: 1.5rem;
    line-height: 1.5rem;
  }
}

.react-datepicker-wrapper {
  display: block;
}
