@import "../../../assets/styles/globals";

.studentDashboardPage {
  .dashboardHeader {
    height: 200px;
    background-image: linear-gradient(
      to right,
      $light-navy,
      $primary-color
    ) !important;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -30px;

    .familyContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex;
      align-items: center;
      flex-direction: row;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      padding-top: 30px;
    }
  }

  .dashboardWelcomeContainer {
    padding: 15px;
    background-image: linear-gradient(
      to right,
      $light-navy,
      $primary-color
    ) !important;

    .dashboardWelcomeInfoWrapper {
      padding-bottom: 20px;

      .dashboardWelcomeAvatar {
        border: 2px solid white !important;

        .roundImage {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .dashboardWelcomeIcon {
      color: white !important;
    }

    .dashboardTimeName {
      font-size: 30px;
      color: white;

      @media all and (max-width: 1600px) {
        font-size: 22px !important;
      }

      @media all and (max-width: 1199px) {
        font-size: 18px !important;
      }
    }

    .dashboardTimeWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .dashboardTime {
        font-size: 18px;
        color: $secondary-color;
        margin-left: 10px;

        @media all and (max-width: 1600px) {
          font-size: 16px !important;
        }

        @media all and (max-width: 1199px) {
          font-size: 13px !important;
        }
      }

      .dashboardTimeText {
        text-transform: uppercase;
        font-size: 18px;
        color: white;

        @media all and (max-width: 1600px) {
          font-size: 16px !important;
        }

        @media all and (max-width: 1199px) {
          font-size: 13px !important;
        }
      }
    }

    .dashboardWelcomeTipWrapper {
      p {
        font-size: 14px;
        color: white;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 10px;
      }

      @media all and (max-width: 1600px) {
        font-size: 12px;
      }
    }
  }

  .familyActivityCard {
    height: 500px;
  }
}
