@import "../../assets/styles/globals";

#EducationProgressBar {
  width: 100%;
  margin-top: 15px;
  p {
    margin-bottom: 10px;
    text-align: left;
    color: $primary-color;
  }

  .progress {
    background-color: $light-navy;
  }

  .progress-bar {
    overflow: initial;
  }

  .progress-bar-text {
    color: white;
    padding: 0px 4px;
    margin: 0px;
    font-size: 12px;
  }

  .education-section-count {
    margin-top: 10px;
  }
}
