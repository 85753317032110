@import "../../assets/styles/globals";

.detailsPanelWrapper {
  @media (min-width: 992px) {
    width: 25%;
  }

  .header {
    height: 125px;
    color: white;
    position: relative;

    @media (max-width: 991px) {
      background: $dark-navy !important;
    }

    @media (min-width: 992px) {
      height: 350px;
    }

    .name {
      font-weight: 800;
      font-size: 1.5rem;

      @supports (-webkit-line-clamp: 1) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      @media (min-width: 992px) {
        text-align: center;
        display: initial;
      }
    }

    .address {
      position: absolute;
      bottom: 1rem;

      @media (max-width: 991px) {
        display: none;
      }
    }

    .bookingLink {
      font-weight: 800;
      color: inherit;
      margin-bottom: 0.25rem;
    }

    .angleRight {
      font-size: 1.25rem;
      font-weight: inherit;
    }

    .switchViewModeButton {
      background: transparent;
      border: none;
      padding: 0;
      width: fit-content;
      font-weight: 800;
      color: inherit;
    }

    .haveQuestionsButton {
      padding: 0;
      background: transparent;
      border: none;
      color: $primary-orange;
      font-weight: inherit;
      text-decoration: underline;
    }
  }
}

.footer {
  border-top: 1px solid $light-navy;
  background: white;
  height: 80px;
}
