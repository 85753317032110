@import "../../../assets/styles/globals";

.schedule-view {
  color: $placeholder-gray;

  p {
    word-break: break-word;
  }

  i {
    text-align: center;
    font-size: 20px;
    min-width: 20px;
    margin-right: 10px;
  }

  .title {
    color: $dark-navy;
    font-size: 18px;
    font-weight: 700;
  }

  .edit-icon {
    color: $primary-orange;
    margin-right: 50px;
    margin-top: -40px;
    cursor: pointer;
    min-width: 25%;
  }

  .orange-bold {
    color: $primary-orange;
    font-weight: 700;
  }

  .description {
    white-space: pre-wrap;
  }
}
