@import "../../../assets/styles/globals";

.header-container {
  padding: 0px 16px;
  height: 130px;
  background-color: $dark-navy;

  @media (min-width: 768px) {
    height: 70px;
  }

  .back-section {
    cursor: pointer;

    .title {
      font-weight: 700;
      color: white;
      font-size: 24px;
      margin-bottom: 0px;
    }
  }

  .action-button {
    height: 36px;
    width: 160px;
    background-color: $light-navy;
    padding: 6px 20px;
    white-space: nowrap;

    &:hover, &:active, &:visited, &:focus, &:focus-visible {
      background-color: $primary-orange !important;
    }

    &:disabled, &:disabled:hover {
      pointer-events: none;
      background-color: $light-navy !important;
    }
  }

  .preview-icon {
    width: 24px;
    height: 24px;

    path {
      fill: white;
    }
  }

  .step {
    cursor: pointer;

    .tab {
      white-space: nowrap;
    }

    .bar {
      display: relative !important;
      margin-top: 10px;
      bottom: 0;
      height: 6px;
      border-radius: 10px;
    }

    .active-bar {
      background-color: $primary-orange;
    }

    .error-bar {
      background-color: #e04b4b;
    }

    .active {
      circle {
        fill: $primary-orange;
      }
    }
  }
}