  .react-tabs [role=tablist] {
    background-color: #f8f8f8;
    margin: 0 0 10px;
    padding: 0;
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }

  .react-tabs [role=tab] {
    margin-top: 10px;
    background: #f8f8f8;
    color: #777;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 300;
    border-top: none !important;
     -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition:         all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .react-tabs [role=tab][aria-selected=true] {
  	background: #f8f8f8;
    color: #2b3843;
    font-weight: 600;
    border-left: none;
    border-right: none;
    border-top: none !important;
    border-bottom: none;
    border-radius: 0 0 0 0 !important;
    -moz-border-radius: 0 0 0 0 !important;
    -webkit-border-radius: 0 0 0 0 !important;
  }

  .react-tabs [role=tab][aria-selected=true]:after {
    content:"";
    background: #2b3843;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 22%;
    height: 5px;
  }

  .react-tabs [role=tab]:after {
    content:"";
    // background: #2b3843;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 22%;
    height: 0px;
    // transition: 3s;
     -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition:         all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }


  .react-tabs [role=tab][aria-disabled=true] {
    color: GrayText;
    cursor: default;
  }

  .react-tabs [role=tab]:focus {
    box-shadow: 0px 0px 0px !important;
    border-color: #f8f8f8;
    outline: none;
  }

  .react-tabs [role=tab]:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #f8f8f8;
  }

