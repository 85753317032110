@import "../../../../../assets/styles/globals";

#RosterSummary {
  .roster-summary-label {
    color: $dark-navy;
    font-size: 24px;
    font-weight: 700;
    margin-top: -15px !important;
    margin-bottom: 18px !important;
  }

  thead {
    background-color: $off-white;
  }


  .used-packages-table-row {
    border-top: 1px solid $placeholder-gray;
    border-bottom: 1px solid $placeholder-gray;

    .package-column {
      .title {
        font-weight: 700;
        word-break: break-word;
        margin-bottom: 0;
      }

      .title-no-package {
        margin-bottom: 0;
      }

      .price {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .separating-dot {
        font-size: 14px;
        color: $light-navy;
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 0;
      }

      .description {
        font-size: 14px;
        color: $light-navy;
        margin-bottom: 0;
      }

      .detached-archived-package {
        font-size: 14px;
        color: #fff;
        background: $placeholder-gray;
        padding: 4px 6px;
        max-width: fit-content;
        border-radius: 8px;
        margin-bottom: 0px;
      }
    }

    .golfers-count-column {
      p {
        margin-bottom: 0;
      }
    }
  }
}
