@import "../../../assets/styles/globals.scss";

#RegistrationPage {
  position: fixed;
  background-image: url("../../../assets/images/auth/Op36Registration.png");
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .image-gradient {
    position: fixed;
    height: 100%;
  }
  .registration-card {
    padding: 0px !important;
  }
  .registration-header-wrapper {
    margin: 0 auto;
    padding-top: 40px;

    background-image: linear-gradient(
      to right,
      lighten($light-navy, 15%),
      $light-navy
    ) !important;

    width: 100%;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;

    .registration-community-image {
      margin: auto;
      border: 2px solid $white-font-color;
    }
    .registration-community-name {
      text-transform: uppercase;
      color: $white-font-color;
      font-weight: 550;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

.registration-loader {
  background-color: $white-font-color;
  border-radius: 8px;

  -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
  margin: auto;
  padding-bottom: 75px;
  margin-top: 10%;
}
