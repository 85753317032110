@import "../../assets/styles/globals";

#CommunityCard {
  &:hover {
    cursor: pointer;
  }
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  overflow: hidden;
  // margin-top: 4px;
  // margin-bottom: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 2px 2px -2px #777;
  position: relative;
  .certified-header {
    display: flex;
    width: 100%;
    align-items: center;
    background: $primary-orange;
    color: #fff;
    font-size: 14px;
    padding: 3px 0 13px 10px;
    position: absolute;
    top: 0;
    left: 0;
    .white-hex {
      height: 14px;
      flex-basis: 14px;
      flex-grow: 0;
      flex-shrink: 0;
      background-size: cover;
      background-position: center;
      margin-right: 5px;
    }
  }
  .top50 {
    background: #33d9b2;
  }
  .community-card-header {
    position: relative;
    background: #fff;
    color: #2b3843;
    height: 30px;
    border-bottom: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    flex-wrap: nowrap;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    p {
      margin: 0;
      font-size: 12px;
    }

    .community-card-types {
      display: flex;

      .is-public {
        margin-right: 8px;
        color: #5f6f7c; // light-navy

        .fa-unlock, .fa-lock {
          margin-right: 4px;
        }
      }

      .community-card-location-type {
        margin-right: 4px;
      }

      .community-card-location-coaching {
        color: #33d9b2; // primary-teal
      }

      .community-card-location-league {
        color: #34ace0; // primary-blue
      }
    }

    .distance-light {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .is-certified {
    margin-top: 25px;
  }
  .community-card-container {
    padding: 10px;
    display: flex;
    align-items: center;
    height: 97px;
    .community-card-photo {
      width: 60px;
      height: 60px;
      margin-right: 5px;
      border-radius: 50%;
      max-height: 100%;
      background-size: cover;
      background-position: center;
      border: 2px solid #2b3843;
    }
    .network-time {
      margin: 4px 0 0 0;
      font-size: 11px;
      padding: 1px;
      color: #fff;
      background: $primary-orange;
      border-radius: 500px;
      text-align: center;
    }
    i {
      color: #111;
      margin-left: auto;
    }
    .community-info-area {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      padding: 0 7px;
      .name {
        color: #222;
        font-size: 16px;
        margin: 0;
        font-weight: 500;
      }
      .address {
        color: #959da6;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        @media screen and (max-width: 1130px) {
          display: none;
        }
      }
    }
  }
  .community-card-footer {
    background: #34404b;
    height: 45px;
    color: #e0e0e0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    p {
      margin: 0;
    }
    .lead-coach-info {
      display: flex;
      align-items: center;
      p {
        margin: 0 5px;
        font-size: 14px;
      }
      .more-coaches {
        font-size: 12px;
        color: #80e4cd;
      }
      .lead-coach-icon {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        border: 2px solid #fff;
        background-position: center;
        background-size: cover;
      }
    }
  }
}
