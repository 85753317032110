@import "../../assets/styles/globals";

.modal {
  background-color: transparent !important;
  height: auto !important;

  .modalOverlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .modalContentContainer::-webkit-scrollbar-thumb {
    background-color: $placeholder-gray;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  .modalContentContainer {
    position: absolute;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 600px;
    overflow-y: auto;
    padding: $secondary-padding $secondary-padding $primary-padding;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.75);
    border-radius: $card-corner-radius;
    z-index: 1000;
    background: #fff;

    &.fixedTop {
      top: 0;
      margin: auto;
      max-height: fit-content;

      @media (min-height: 576px) {
        top: 100px;
        margin: 0 auto;
      }
    }

    .header {
      display: flex;

      .title {
        flex: 1;
      }

      .closeButton {
        background-color: transparent;
        border: none;
        padding-right: 15px;

        &:focus,
        &:focus-visible,
        &:active,
        &:hover {
          border: none;
          outline: none;
          text-decoration: none;
          background-color: transparent;
        }

        &:hover {
          transform: scale(0.95);
          transition: all 0.2s ease-in-out;
        }

        .closeIcon {
          &.darkModal {
            fill: $off-white;
          }
        }
      }
    }
  }
}