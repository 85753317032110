@import "../../assets/styles/globals";

#CommunityMapPage {
  .content-container {
    // height: calc(100% - 60px);
    width: 100%;
    display: flex;
    @media screen and (max-width: 830px) {
      flex-direction: column;
    }
    .location-permission-container {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .map-sidebar {
      width: 25%;
      height: 100%;
      background: #f4f4f5;
      color: #2b3843;
      padding: 0;
      border-right: 1px solid #ccc;
      @media screen and (max-width: 1030px) {
        width: 30%;
      }
      @media screen and (max-width: 830px) {
        width: 100%;
      }
    }

    .map-area {
      width: 75%;
      // height: 100%;
      position: relative;
      background: #f1f1f1;

      @media screen and (max-width: 1030px) {
        width: 70%;
      }
      @media screen and (max-width: 830px) {
        width: 100%;
      }
      .map-outer-container {
        height: calc(100vh - 60px);
      }
      .map-container {
        height: calc(100% - 45px);
        position: relative;
      }
      .map-bottom-bar {
        background: #1d2934;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fcfcfb;
        padding: 0 15px;
        .map-bottom-bar-text {
          // font-size: 0.9rem;
          font-size: 14px;
          margin-bottom: 0;
          text-align: center;
          .map-bottom-bar-link {
            color: $primary-orange;
            border-bottom: 1px dotted $primary-orange;
            white-space: nowrap;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .community-detail {
      width: 80%;
      height: 100%;
      background: #f0f0f0;
    }

    .grey-out {
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: rgba(43, 56, 67, 0.85);
      z-index: 1000009; // google maps sets their map to z-index 1,000,000
    }
  }
}
