@import "../../../../assets/styles/globals.scss";

#FamilyRegisterForm {
  .form-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    label {
      color: $light-navy;
      margin-bottom: 4px;
      margin-top: 15px;
      font-size: 14px;
    }

    .default-option {
      color: $light-gray;
    }

    .golfer-selection-row {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;

      button {
        margin: 10px;
        width: 100px;
      }
    }
  }

  .checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 50px;

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;

      p {
        color: $light-navy;
        margin-bottom: 0px;
        margin-left: 15px;
        font-size: 14px;
      }

      a {
        color: $secondary-color;

        &:hover {
          color: darken($secondary-color, 15%);
        }
      }
    }
  }

  .required-text {
    color: $light-navy;
    padding-bottom: 30px;
    padding-top: 15px;
    font-size: 13px;
  }

  .form-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    button {
      margin: auto;
      margin-top: 20px;
      width: 48%;
    }
  }

  .react-tel-input {
    width: 100%;
    border: none !important;

    .flag-dropdown {
      background-color: transparent;
      border: none;
      top: auto;
    }

    input {
      padding-left: 50px;
      box-shadow: none !important;
    }

    button {
      border: none;
      background-color: transparent !important;
    }
  }
}
