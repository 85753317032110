@import "../../../../../assets/styles/globals";

.series-schedule {
  margin-top: 20px;
  margin-left: 20px;

  .title {
    color: $primary-navy;
    font-size: 20px;
    font-weight: 800;
  }

  .events {
    border-bottom: 1px solid $placeholder-gray;
  }
}