@import "../../../assets/styles/globals";

#ClassScheduleView {
  .schedule-header {
    margin: -10px -15px 0px -15px;
    background-color: #fff;
    padding: 18px 0px;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
    }

    .header-button {
      color:$primary-orange;
      padding: 0px 18px;
      cursor: pointer;
      text-align: center;
    }
  }
}