@import '../../../assets/styles/globals.scss';

.exit-modal {
  max-height: fit-content !important;
}

#ExitModal {
  margin: auto !important;
  width: 550px !important;
  height: 435px !important;
  padding: 24px !important;
  border-radius: 8px !important;
  background: white !important;

  .modal-container {
    .modal-content {
      width: 90%;
      text-align: center;
      p {
        margin-bottom: 0;
        font-weight: 600;
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        color: $primary-orange;
        margin-left: 15px;
        padding-block: 10px;
      }
    }

    .icon-wrapper {
      background-color: $primary-orange;
      border-radius: 100px;
      width: 97px;
      height: 97px;
    }
  }
}