$placeholder-color: #eee;

#ForgotUsernameEmailForm {
  h4 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  #reset-password-form {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $placeholder-color;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $placeholder-color;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $placeholder-color;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $placeholder-color;
    }
  }
}
