@import "../../../../assets/styles/globals";

#EducationSidebar {
  background-color: $light-gray;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 25px;

  .education-sidebar-header {
    border-bottom: 1px solid $light-font-color;
    margin-bottom: 25px;
    h3 {
      width: 90%;
      margin-left: 25px;
      font-weight: 550;
      font-size: 25px;
      padding-bottom: 20px;
      color: $primary-color;
    }

    p {
      margin-left: 25px;
      color: $light-navy;
      font-weight: 550;
    }

    .progress {
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 15px;

      .progress-bar-text {
        margin: 0px;
        color: white;
        font-weight: 400;
      }
    }
  }
}
