@import "../../assets/styles/globals";

#AnimatedScrollView {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .animated-scrollview-wrapper {
    height: 10%;

    #AnimatedView {
      color: $white-font-color;
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding: 15px;

      h5 {
        font-weight: 550;
        font-size: 30px;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
      }

      p {
        text-align: center;
        width: 100%;
      }

      a {
        margin-top: 30px;
      }

      .action-wrapper {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;

        a {
          margin: 10px;
          font-size: 22px;
        }
      }
    }
  }

  .animated-scrollview-tabs-wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      background-color: transparent;
      font-size: 12px;
      margin-bottom: 25px;
    }
  }
}
