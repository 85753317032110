@import "../../../assets/styles/globals";

#ClassDetailHeader.classDetailHeader {
  padding-top: 10px;
  .class-detail-top-button-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 36px;
      &:hover {
        color: #f4f4f5;
        cursor: pointer;
      }
    }
    .fa-chevron-down {
      font-size: 24px;
      position: relative;

      .class-button-popover {
        background: #fff;
        border-radius: 8px;
        left: -100px;
        position: absolute;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        z-index: 10;
        padding: 0 10px 10px 10px;
        &:hover {
          cursor: default;
        }
        p {
          color: black;
          margin: 0;
          margin-top: 10px;
          font-size: 1rem;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .delete {
          color: $primary-orange;
        }
      }
    }
    .invert {
      color: $primary-orange;
      &:hover {
        color: $primary-orange;
      }
    }

    .view-sign-up-page {
      padding: 0 15px;

      #outsideLinkIcon {
        flex-shrink: 0;
      }

      .view-sign-up-page-label {
        padding-left: 10px;
        color: $primary-orange;
        font-weight: 700;
      }
    }
  }
  .mx-15 {
    margin: 0 -15px;
  }
}
