@import "../../../assets/styles/globals";
$populate-roster-top-content-height: 250px;

#ManageRoster {
  height: 100%;

  .populate-roster {
    background-color: #fff;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 30px;

    .main-hr {
      margin-left: -70px;
      margin-right: -20px;
      margin-bottom: 0;
    }

    .search-wrapper {
      width: 85%;
    }

    .roster-search-bar {
      background-color: $off-white;
      border-radius: 8px;
    }

    .students-list {
      height: calc(100% - $populate-roster-top-content-height);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .roster-summary {
    background-color: $off-white;
    padding-top: 30px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    .students-list {
      width: 95%;
    }

    .no-students-placeholder {
      width: 60%;
      margin: 100px auto;
      padding: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .placeholder-title {
    text-align: center;
    margin-top: 1rem;
    font-weight: 800;
    font-size: 20px;
    color: $dark-navy;
  }

  .placeholder-description {
    white-space: pre-wrap;
    text-align: center;
    color: $light-navy;
  }

  .large-text {
    font-size: 20px;
    font-weight: 800;
  }

  .switcher-wrapper {
    border: 2px solid $primary-orange;
    border-radius: 100px;

    button {
      padding: 0 15px;
      color: $primary-orange;

      &:hover {
        color: $primary-orange;
        background-color: lighten($light-gray, 10%);
      }
    }

    .active-option {
      background-color: $primary-orange;
      color: $white-font-color;

      &:hover {
        color: $white-font-color;
        background-color: $primary-orange;
      }
    }
  }
}
