@import "../../assets/styles/globals";

#StateSwitcher {
  margin-bottom: 25px;

  .switcher-wrapper {
    border: 1px solid $light-navy;
    border-radius: 5px;
    overflow: hidden;

    button {
      background-color: transparent;
      border: none;
      border-radius: 100px;
      color: $light-navy;

      &:hover {
        color: $primary-color;
        background-color: lighten($light-gray, 10%);
      }
    }

    .active-option {
      background-color: $light-navy;
      color: $white-font-color;

      &:hover {
        color: $white-font-color;
        background-color: $light-navy;
      }
    }
  }

  .disabled {
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
  }
}
