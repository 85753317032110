@import '../../globals';

.activitySocialMenu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;

  .iconImage{
    border: solid 2px $white-font-color;
    border-radius: 50%;
    transition: .5s;
    &:hover{
      background-color: $secondary-color;
      color: $secondary-color;
      box-shadow: 0px 0px 20px $secondary-color;
    }
  }

  .darkTransparentOverlay{
    z-index: 9999;
  }
  #playActivityContainer,
  #socialActivityContainer,
  #trainActivityContainer,
  #announcementActivityContainer {
    position: absolute;
    z-index: 99999;
  }
  .activityText{
    width: 100%;
    font-size: 20px;
    text-align: center;
    padding-top: 5px;
    color: white;
  }
  .activityIcon{
    text-align: center;
    img{
      width: 80px;
      height: 80px;
    }
  }
  .activityIconSmall{
    img{
      width: 65px;
      height: 65px;
    }
  }
}
