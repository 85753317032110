@import '../../assets/styles/globals';

.endOfList {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed $dark-gray;
  color: $dark-gray;

  span {
    padding-left: 5px;
  }
}
