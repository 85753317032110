@import '../../../assets/styles/globals.scss';

.modal {
  height: fit-content;
  width: 550px;

  .wrapper {
    width: 85%;
    margin: 1.5rem auto;

    .text-content {
      width: 65%;

      .title-container {
        margin-block: 19px;

        .icon-wrapper {
          background-color: $primary-orange;
          border-radius: 100px;
          width: 50px;
          height: 50px;
        }

        .title {
          font-size: 24px;
          font-weight: 700;
          color: $primary-orange;
          margin-left: 15px;
        }
      }

      .text-container {
        white-space: pre-wrap;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .image {
      width: 35%;

      .mobile-leaderboard {
        width: 130px;
      }
    }
  }
}
