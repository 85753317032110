@import "../../assets/styles/globals";

.series-event-card {
  display: flex;
  justify-content: center;
  background-color: $light-gray-blue;
  border-bottom: 1px solid;
  border-color: $light-gray;
  cursor: pointer;
  height: 90px;

  &:hover {
    background-color: #fff;
  }

  .date-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px;
    font-size: 12px;

    .day-number {
      font-weight: 700;
      font-size: 24px;
      color: $dark-navy;
    }

    .month-name {
      font-weight: 700;
      color: $dark-navy;
    }

    .day-name {
      color: $light-navy
    }
  }

  .event-info {
    .event-title {
      font-size: 16px;
      font-weight: 800;
    }

    .event-data {
      font-size: 14px;
    }

    .vertical-line {
      width: 2px;
      background: $light-gray;
      height: 80%;
      margin-inline: 20px;
    }
  }

  .actions-col {
    margin-block: auto;
    text-align: center;
    max-width: 60px;
  }

  .vl {
    border-left: 2px solid $placeholder-gray;
    height: 60px;
    margin: auto 0;
    margin-left: 18px;
    padding-left: 18px;
  }
}
