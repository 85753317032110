@import "buttons";
@import "widgets";
@import "helpers/sizes";

.w-fit-content {
  width: fit-content !important;
}

.padding-base {
  padding: 10px;
}

.pt-15px {
  padding-top: 15px;
}
.pb-15px {
  padding-bottom: 15px;
}
.mt-15px {
  margin-top: 15px;
}
.mb-15px {
  margin-bottom: 15px;
}
.mx--15 {
  margin-left: -15px;
  margin-right: -15px;
}

.bl-0 {
  border-left: none !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.br-0 {
  border-right: none !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.h {
  &-none {
    height: 0px;
    overflow: hidden;
  }
  &-auto {
    height: auto;
  }
}
.spacer {
  &-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-md {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-lg {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

// This can be removed an replaced with the Boostrap 4 spacing Utilities
.spacer-web {
  &-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-md {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &-lg {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

// HELPER CLASSES
.primary-color-bg {
  background-color: $primary-color;
  color: $secondary-color;
}

.hide {
  display: none !important;
}

.primary-text {
  color: $primary-color;
}
.primary-background {
  background-color: $primary-color;
}

.success-background {
  background-color: #67d6b4;
}

.secondary-text {
  color: $secondary-color;
}
.secondary-background {
  background-color: $secondary-color;
}

.bg-muted {
  background-color: #dedede;
}

.mid-font-text {
  color: $mid-font-color;
}
.mid-font-background {
  background-color: $mid-font-color;
}

.text-white {
  color: $white-font-color;
}

.text-primary {
  color: $primary-color;
}

.white-background,
.bg-white {
  background-color: $white-font-color;
}
.pills {
  padding: 10px;
  @include border-radius(50px);
}
.overlay {
  &-5 {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &-3 {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

$borderBase: solid #ccc 1px;

.border {
  border: solid $borderBase 1px;
  &-y {
    border-top: $borderBase;
    border-bottom: $borderBase;
  }
  &-x {
    border-left: $borderBase;
    border-right: $borderBase;
  }
  &-top {
    border-top: $borderBase;
  }
  &-bottom {
    border-bottom: $borderBase;
  }
  &-right {
    border-right: $borderBase;
  }
  &-left {
    border-left: $borderBase;
  }
}

.disabled-state {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.objective-rank-1 {
  background-color: $objective-rank-1;
}
.objective-rank-2 {
  background-color: $objective-rank-2;
}
.objective-rank-3 {
  background-color: $objective-rank-3;
}
.objective-rank-4 {
  background-color: $objective-rank-4;
}
.objective-rank-5 {
  background-color: $objective-rank-5;
}
.objective-rank-6 {
  background-color: $objective-rank-6;
}
.objective-rank-7 {
  background-color: $objective-rank-7;
}
.objective-rank-8 {
  background-color: $objective-rank-8;
}
.objective-rank-9 {
  background-color: $objective-rank-9;
}
.objective-rank-10 {
  background-color: $objective-rank-10;
}

.objective-rank-1-text {
  color: $curriculum-rank-01-color;
}
.objective-rank-2-text {
  color: $curriculum-rank-02-color;
}
.objective-rank-3-text {
  color: $curriculum-rank-03-color;
}
.objective-rank-4-text {
  color: $curriculum-rank-04-color;
}
.objective-rank-5-text {
  color: $curriculum-rank-05-color;
}
.objective-rank-6-text {
  color: $curriculum-rank-06-color;
}

.stat-color-1-bg {
  background-color: $stat-color-1;
}
.stat-color-2-bg {
  background-color: $stat-color-2;
}
.stat-color-3-bg {
  background-color: $stat-color-3;
}
.stat-color-4-bg {
  background-color: $stat-color-4;
}
.stat-color-5-bg {
  background-color: $stat-color-5;
}
.stat-color-6-bg {
  background-color: $stat-color-6;
}
.stat-color-7-bg {
  background-color: $stat-color-7;
}

.stat-color-1-text {
  color: $stat-color-1;
}
.stat-color-2-text {
  color: $stat-color-2;
}
.stat-color-3-text {
  color: $stat-color-3;
}
.stat-color-4-text {
  color: $stat-color-4;
}
.stat-color-5-text {
  color: $stat-color-5;
}
.stat-color-6-text {
  color: $stat-color-6;
}
.stat-color-7-text {
  color: $stat-color-7;
}

// This is a temp debug because of a Bootstrap version issue:

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
