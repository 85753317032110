@import "../../../assets/styles/globals";

.enrollmentsTooltip {
  background-color: white !important;
  border-color: $light-gray !important;
  border-radius: 10px !important;
  color: $dark-gray !important;
  opacity: 1 !important;
  width: 15%;
  padding: 0px !important;
  max-height: 30%;
  overflow-y: auto;

  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }

  .tooltipTitle {
    margin: 0px;
    font-weight: 700;
  }

  .tooltipSection {
    padding: 10px 15px;
    border-bottom: 1px solid $light-gray;
  }
}

.golfersInfo {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;

  .golfersCount {
    color: $dark-gray;
    font-weight: 700;
    padding-bottom: 5px;
    font-size: 14px;
    width: 10rem;

    .count {
      font-size: 18px;
    }
  }

  .progressBackground {
    border-radius: 1.25rem;
    width: 10rem;
    height: 0.75rem;
    background-color: $primary-navy;
  }
}

.progressBar {
  background-color: $dark-teal;
  border-radius: 1.25rem;
}

.studentRow{
  display: flex;
  align-items: center;
  padding: 5px 10px;

  .studentName {
    padding-left: 10px;
    color: black;
    font-size: 12px;
  }

  .profileImg {
    border-radius: 50%;
    border: 1px solid $icon-gray;
    height: 35px;
    width: 35px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
    margin-left: -10px;
  }
}
