@import "../../../assets/styles/globals";

.deleteAccountModalContent {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  .modalXIcon {
    cursor: pointer;
  }

  .warningIcon {
    margin-top: 16px;
  }

  .title {
    color: $primary-orange;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    color: '#E79235';
    font-size: 28px;
    font-weight: 700;
  }

  .message {
    word-break: break-word;
    margin-bottom: 6px;
  }
}
