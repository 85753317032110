@import "../../../../assets/styles/globals";

#EducationSection {
  .section-container {
    background-color: lighten($light-gray, 10%);
    border: 1px solid $light-gray !important;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;

    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      width: 100%;
      color: $primary-color;
      text-align: left;
      padding-left: 10px;
      padding-top: 5px;

      h5 {
        color: $primary-color;
        font-weight: 550;
      }

      p {
        margin-bottom: 0px;
      }

      .section-completed-count-wrapper {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 0px;
        color: $primary-color;
      }

      .completed-section {
        font-weight: 550;
        color: $secondary-color;
      }
    }
  }
}
