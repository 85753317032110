@import "../../assets/styles/globals";

#NoNearbyCommunities {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c0c0c0;
  i {
    font-size: 80px;
    margin-top: 10px;
  }
  img {
    // margin-top: 10px;
    height: 100px;
  }
  button {
    width: 80%;
    background: $primary-orange;
    border-radius: 500px;
    box-shadow: 4px 4px 4px #555;
    &:hover {
      position: relative;
      bottom: -2px;
      right: -2px;
      box-shadow: 2px 2px 2px #555;
      color: #f4f4f5;
    }
  }
}
