@import "../../assets/styles/globals";
#EducationsPage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px); // 70px is height of main topnav
  width: calc(100% + 30px);
  margin: 0 -15px 0 -15px;

  .topnav-title {
    line-height: 1;
    font-weight: $bold-font-weight;
    padding: 0;
    margin: 0;
    // margin-bottom: $secondary-padding;
  }

  .topnav-tabs-buttons-wrapper {
    padding: 0px;
    display: flex;
    align-items: flex-end;

    .topnav-tabs-wrapper {
      display: flex;
    }
  }

  .active-pg {
    color: $primary-orange;
  }

  .lesson-dashboard-topnav {
    width: 100%;
    background: $dark-navy;
    color: #fff;
    // padding: 10px;
    padding: $secondary-padding;
    padding-bottom: 0px;
    position: relative;
    box-shadow: $topnav-drop-shadow;

    .bars-icon {
      font-size: 1.5rem;
      &:hover {
        cursor: pointer;
      }
    }
    // .picker-popover {
    //   position: absolute;
    //   background: #fff;
    //   color: $dark-navy;
    //   z-index: 1000;
    //   padding: 15px 25px 5px 15px;
    //   border-radius: 8px;
    //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //   p {
    //     margin-bottom: 10px;
    //     font-size: 18px;
    //     &:hover {
    //       cursor: pointer;
    //       text-decoration: underline;
    //     }
    //   }
    // }

    .nav-search-area {
      display: flex;
      margin: 0 auto;
      margin-right: 0px;
      height: 48px;
    }
    .finish-hover-bar,
    .drafts-hover-bar,
    .all-plans-hover-bar,
    .in-use-hover-bar {
      height: 4px;
      border-radius: 4px;
    }

    .finish-container,
    .drafts-container,
    .all-plans-container,
    .in-use-container {
      &:hover {
        cursor: pointer;
      }
    }

    .make-active-hover {
      background: $primary-orange;
    }

    .lesson-status-select {
      width: 200px;
      p {
        margin-bottom: 2px;
        text-align: center;
      }
      &:hover {
        color: $primary-orange;
      }
    }
    .add-button-1 {
      // position: fixed;
      // bottom: 35px;
      // right: 35px;
      height: 40px;
      // width: 80px;
      padding: 0 15px;
      border-radius: 500px;
      background: $primary-orange;
      color: #fff;
      box-shadow: $pressable-elem-drop-shadow;
      z-index: 98;
      white-space: nowrap;
      &:hover {
        cursor: pointer;
      }
      // i {
      //   font-size: 2.5rem;
      // }
    }
  }

  .education-content-container {
    height: 100%;
    margin-right: -15px;
    display: flex;
    h3 {
      font-size: $heading-3-size;
      font-weight: $bold-font-weight;
      margin: 0;
      margin-bottom: $secondary-padding;
      line-height: 1;
    }

    .in-progress-sidebar {
      color: white;
      background-color: $light-navy;
      // padding: $primary-padding;
      padding: $secondary-padding;
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      max-height: calc(100vh - 164px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.5);
        background-color: rgba(104, 104, 104, 0.5);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      }

      .pinned-toggler {
        display: flex;
        align-items: center;
        margin-bottom: $secondary-padding;
        i {
          transform: rotate(0deg);
          transition: transform 0.3s;
        }
        .flip {
          transform: rotate(180deg);
        }
        h3 {
          margin: 0;
          margin-left: 10px;
        }

        &:hover {
          cursor: pointer;
          color: darken(white, 15%);
        }
      }

      .empty-sidebar-msg {
        // margin: auto;
        display: flex;
        flex-flow: column;
        // justify-content: flex-start;
        align-items: center;
        opacity: 0.5;
        padding-top: 150px;
        img {
          width: 92px;
          height: 72px;
          filter: brightness(0) invert(1);
          margin-bottom: 10px;
        }

        p {
          text-align: center;
        }
      }
    }

    .drills-all-main-area {
      max-height: calc(100vh - 164px);
      overflow-y: auto;
      flex: auto;
      &::-webkit-scrollbar {
        width: 8px !important;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.5);
        // background-color: rgba(104, 104, 104, 0.5);
        background-color: #f4f4f5;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // background-color: rgba(255, 255, 255, 0.8);
        background-color: rgba(104, 104, 104, 0.5);
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      }
      .promo-banner {
        background: $primary-orange;
        color: #fff;
        padding: 15px;
        p {
          margin: 0;
          text-align: center;
          .count-down {
            font-weight: bold;
          }
        }
        .price-preview {
          font-size: 18px;
        }
        .strike-through {
          text-decoration: line-through;
          font-size: 16px;
          color: #f4f4f5;
        }
      }
    }

    .drills-activities-cards-main-area {
      padding: $secondary-padding;
    }
  }
}
