@import '../../../assets/styles/globals.scss';

.class-picker-wrapper {
  .check-box {
    border: 2px solid $primary-orange;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }

  .check-box-wrapper {
    min-width: 60px;
  }

  .package-select-wrapper {
    padding-left: 60px;
    padding-right: 35px;
  }

  .checked {
    background-color: $primary-orange;
  }

  .class-title {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 0;
  }

  .class-date {
    color: $light-navy;
  }

  .package-select-label {
    font-weight: 800;
  }

  .enrollments-count {
    font-size: 20px;
    font-weight: 700;
  }
}