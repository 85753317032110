@import "../../globals";

#leaderboardWrapper {
  margin-top: 10px;
  * .seg-control-tab {
    position: absolute;
    margin-top: 0px;
    z-index: 999;
    width: 100%;
  }
  &.componentBody.container {
    padding-top: 0;
  }
}

.leaderBoardItemWrapper {
  margin-top: 41px;
}
.comment {
  // display: flex;
  // align-items: center;
  padding: 10px 0;
  margin-top: 0;
  margin-bottom: 0;

  .commentIndex {
    h4 {
      margin-bottom: 0;
    }
  }

  .commentContent {
    h4 {
      font-size: 22px;
    }
  }

  .commentBody {
    text-align: right;
    margin-bottom: 0;
    color: $secondary-color;

    .pt-num {
      margin-right: 6px;
      font-size: 30px;
      font-weight: 300;
    }

    .pt-label {
      font-size: 20px;
      margin-top: -3px;
      position: relative;
      top: -6px;
    }
  }
}
