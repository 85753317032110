@import "../../../assets/styles/globals";

.cancel-button {
  background-color: transparent;
  color: $primary-orange !important;
  border: none;

  &:focus, &:active {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.btn-dark {
  color: white !important
}
