#ScheduleSessionModal{
  .delete-button {
    margin-right: 50px;
    margin-top: -40px;
  }

  .form-title {
    margin-top: -40px;
  }
}

