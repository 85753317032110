@import "../../../assets/styles/globals";

.programCard {
  height: 170px;
  background: white;
  border: none;
  border-left: 4px solid;
  border-radius: 15px;
  padding: 1.2rem 1rem;
  transition: border-left 0.2s;
  color: $dark-navy;
  position: relative;

  @media (min-width: 992px) {
    height: 100px;
  }

  &:hover {
    color: $dark-navy;
    border-left-width: 10px;
  }

  .programInfoWrapper {
    width: 100%;
  }

  .visibilityIcon {
    margin-right: 1rem;
    height: 19px;
    width: 19px;
    path {
      fill: $placeholder-gray;
    }
  }

  .visibilityTooltip {
    background-color: white !important;
    border-color: $icon-gray !important;
    border-radius: 10px !important;
    color: $dark-navy !important;
    font-weight: 600;
  }

  .descriptionWrapper {
    width: 45%;

    .name {
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 800;
  
      @media (min-width: 992px) {
        font-size: 1.2rem;
      }
    }
  
    .subtitle {
      color: $light-navy;
      line-height: 1rem;
      font-size: 0.9rem;
      flex-basis: 50% !important;
  
      @media (min-width: 992px) {
        flex-basis: 33%;
        font-size: 1rem;
      }
  
      .subtitleIcon {
        fill: $light-navy;
        height: 1rem !important;
        width: auto;
  
        @media (min-width: 992px) {
          height: 1.2rem;
        }
      }
    }
  }

  .description {
    margin: auto;
    color: $light-navy;
    font-weight: 700;
  }

  .actionsButton {
    background-color: transparent;
    border: none;
  }

  .enrollmentsBadge {
    background-color: $primary-orange;
    position: absolute;
    border-radius: 2rem;
    padding: 3px 10px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    top: -1rem;
    right: 2rem;
  }
}

.delete {
  color: $red-delete !important;
}

@media all and (max-width: 575px) {
  .programCard {
    padding: 0.7rem 0.5rem;
    flex-direction: column;

    .visibilityIcon {
      margin-right: 0.5rem;
    }

    .programInfoWrapper {
      flex-direction: column;
      width: 90%;

      .descriptionWrapper {
        width: 90%;
      }
    }

    .action {
      position: absolute;
      top: 40%;
      right: 10px;
    }
  }
}