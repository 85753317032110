@import "../../globals";

#LeaderboardList{
  .leaderBoardItemWrapper{
      margin-top: 41px;
  }  
  * .seg-control-tab{
    position: absolute;
    margin-top: 0px;
    z-index: 999;
    width: 100%;    
  }
}

.leaderboardWrapper-web {
	.react-tabs {
		margin-right: -30px;
	}

	&#leaderboardWrapper * .seg-control-tab{
		position: relative !important;
		margin-left: -15px;
		margin-right: -15px;
		margin: 0px;
	}
	#leaderboardWrapper{
		margin-top: 10px !important;
	}
	#LeaderboardPage{
	  .leaderBoardItemWrapper{
	      margin-top: 10px !important;
	  }  
	  * .seg-control-tab{
	    position: relative !important;
	    margin-top: inherit;
	    z-index: 999;
	    width: 100%;    
	  }
	}
	* .commentBody {
		font-size: 10px !important;
	}

}	
