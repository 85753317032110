@import '../../assets/styles/globals';

.eventStatus {
  p {
    font-weight: 800;
    margin-bottom: 0 !important;
  }

  .created {
    display: flex;
    align-items: center;
    color: $primary-navy;

    .iconWrapper {
      background-color: $primary-navy;
    }

    .rsvpLabel {
      background-color: $slate-gray;
      width: fit-content;
      padding: 0 15px;
    }
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    min-width: 21px;
    height: 21px;
    border-radius: 500px;

    i {
      font-size: 13px;
      color: #fff;
    }
  }

  .ready {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $primary-blue;

    .iconWrapper {
      background-color: $primary-blue;
    }

    .attendingText {
      color: $light-navy;
      font-weight: 800;
      font-size: 14px;
    }
  }

  .ongoing {
    display: flex;
    align-items: center;
    color: $primary-orange;

    .iconWrapper {
      background-color: $primary-orange;
    }
  }

  .completed {
    color: $dark-teal;
    font-size: 14px;

    .iconWrapper {
      background-color: $dark-teal;
    }

    .leaderboardLabel {
      background-color: $dark-teal;
      width: 140px;
      padding: 1px 3px;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500vh;

    .labelText {
      color: $white-font-color;
      margin: 0px;
      font-size: 14px;
      font-weight: 800;
    }
  }
}
