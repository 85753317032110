@import "../../../../assets/styles/globals.scss";

#FinishedStep {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;

  .icon {
    font-size: 120px;
    color: $light-navy;
  }

  .title {
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 550;
    font-size: 40px;
    margin-top: 15px;
  }

  .message {
    color: $primary-color;
    margin: 0px;
  }

  button {
    margin-top: 30px;
  }
}
